import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {NbDialogService, NbIconLibraries} from '@nebular/theme';
import {LoggerService, LogLevel} from '@app/service/logger/logger.service';
import {environment} from '@env';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {LoginSuccess, Logout} from '@shared/state/auth/auth.actions';
import {RoutingService} from '@app/service/routing.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {Observable, Subject} from 'rxjs';
import {MessagingService} from '@shared/service/messaging.service';
import {FacebookService, InitParams} from 'ngx-facebook';
import {takeUntil} from "rxjs/operators";
import {Idle} from 'idlejs';

@Component({
  selector: 'dlr-root',
  templateUrl: './app.view.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('logoutDialog') logoutDialog: TemplateRef<any> | any;
  @Select(AuthState.getInitialized) initialized$: Observable<boolean>;
  message;
  curTime: string;
  idleWarning: Idle;
  idleLogout: Idle;

  constructor(private iconLibraries: NbIconLibraries, private logService: LoggerService, private actions: Actions,
              private routingService: RoutingService, private msgService: MessagingService, private store: Store,
              private facebookService: FacebookService, private dialogService: NbDialogService) {
    this.iconLibraries.registerFontPack('solid', {packClass: 'fas', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('regular', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('light', {packClass: 'fal', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('duotone', {packClass: 'fad', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('brands', {packClass: 'fab', iconClassPrefix: 'fa'});

    this.iconLibraries.setDefaultPack('solid');
  }

  ngOnInit(): void {
    this.actions.pipe(ofActionDispatched(Logout))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.routingService.navigateLogin();
        if (this.idleWarning) {
          this.idleWarning.stop();
        }
        if (this.idleLogout) {
          this.idleLogout.stop();
        }
      });
    this.actions.pipe(ofActionDispatched(LoginSuccess))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.idleWarning = new Idle()
          // .whenNotInteractive()
          .whenNot([{ // Workaround to make logout happen unless dismiss button is clicked
            events: ['input', 'click', 'mouseenter', 'keydown'],
            target: document,
          }])
          .within(58) // If idle for 1hr, log user out
          .do(() => this.showLogoutDialog())
          .start();
      });

    // Enable the FCM notification service and request permission if needed
    this.msgService.requestPermission();
    this.logService.level = environment.local ? LogLevel.All : LogLevel.Error;
    // this.msgService.getPermission();
    // this.msgService.receiveMessage();
    // this.message = this.msgService.currentMessage;
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initFacebookService(): Promise<any> {
    const initParams: InitParams = {
      appId: '661047864683882',
      xfbml: true,
      version: 'v8.0'
    };
    return this.facebookService.init(initParams);
  }

  logout() {
    return this.store.dispatch(new Logout());
  }

  restartIdle(ref: any) {
    ref.close();
    if (this.idleWarning) {
      this.idleWarning.start();
    }
    if (this.idleLogout) {
      this.idleLogout.stop();
    }
  }

  showLogoutDialog() {
    const dialogRef = this.dialogService.open(this.logoutDialog, {
      closeOnBackdropClick: false
    });
    this.idleWarning.stop();
    this.idleLogout = new Idle()
      .whenNot([{ // Workaround to make logout happen unless dismiss button is clicked
        events: ['input'],
        target: document,
      }])
      .within(2)
      .do(() => {
        this.logout();
        dialogRef.close();
      })
      .start();
  }
}
