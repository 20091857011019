
<h1 class="main-title"><span class="thin">Dealer</span> Curator <span class="thin beta-text">beta</span></h1>
<p class="sub-title">Create your curated account</p>

<form (ngSubmit)="register()" [formGroup]="registerForm" aria-labelledby="sub-title">

  <div class="form-control-group">
    <label class="label" for="input-name">Full Name</label>
    <input nbInput
           formControlName="fullName"
           id="input-name"
           placeholder="Full Name"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="f.fullName.dirty ? (f.fullName.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.fullName.invalid && f.fullName.touched ? true : null">
    <ng-container *ngIf="f.fullName.invalid && f.fullName.touched">
      <p class="caption status-danger" *ngIf="f.fullName.errors?.required">
        Full name is required!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-email">Email</label>
    <input nbInput
           formControlName="email"
           id="input-email"
           placeholder="Email Address"
           fullWidth
           fieldSize="large"
           [status]="f.email.dirty ? (f.email.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.email.invalid && f.email.touched ? true : null">
    <ng-container *ngIf="f.email.invalid && f.email.touched">
      <p class="caption status-danger" *ngIf="f.email.errors?.required">
        Email is required!
      </p>
      <p class="caption status-danger" *ngIf="f.email.errors?.email">
        Email must be a valid email address!
      </p>
    </ng-container>
  </div>


  <div class="form-control-group">
    <label class="label" for="company-name">Company Name</label>
    <input nbInput
           formControlName="orgName"
           id="company-name"
           autocomplete="off"
           placeholder="Company Name"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="f.orgName.dirty ? (f.orgName.invalid ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.orgName.invalid && f.orgName.touched ? true : null">
    <ng-container *ngIf="f.orgName.invalid && f.orgName.touched">
      <p class="caption status-danger" *ngIf="f.orgName.errors?.required">
        Your company's name is required!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="company-type">Company Type</label>
    <nb-select placeholder="Company Type" formControlName="orgType"
               id="company-type"
               fullWidth
               [size]="'large'"
               [status]="f.orgType.dirty ? (f.orgType.invalid ? 'danger' : 'success') : 'basic'">
      <nb-option value="agency">Marketing Agency</nb-option>
      <nb-option value="dealership">Dealership</nb-option>
    </nb-select>
    <ng-container *ngIf="f.orgType.invalid && f.orgType.touched">
      <p class="caption status-danger" *ngIf="f.orgType.errors?.required">
        Type of company is required!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password</label>
    <input nbInput autocomplete="on"
           formControlName="password"
           type="password"
           id="input-password"
           placeholder="Password"
           fullWidth
           fieldSize="large"
           [status]="f.password.dirty ? (f.password.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.password.invalid && f.password.touched ? true : null">
    <ng-container *ngIf="f.password.invalid && f.password.touched">
      <p class="caption status-danger" *ngIf="f.password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="f.password.errors?.minlength">
        Password must be at least 6 characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-re-password">Confirm Password</label>
    <input nbInput autocomplete="on"
           type="password"
           formControlName="confirmPassword"
           id="input-re-password"
           placeholder="Confirm Password"
           fullWidth
           fieldSize="large"
           [status]="f.confirmPassword.dirty ? (f.confirmPassword.invalid || f.password.value != f.confirmPassword.value  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.confirmPassword.invalid && f.confirmPassword.touched ? true : null">
    <ng-container *ngIf="f.confirmPassword.invalid && f.confirmPassword.touched">
      <p class="caption status-danger" *ngIf="f.confirmPassword.errors?.required">
        Password confirmation is required!
      </p>
      <p class="caption status-danger" *ngIf="f.confirmPassword.errors.mustMatch">
        Passwords do not match.
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <p class="text-center pl-3 pr-3">
      By creating an account, you agree to our
      <a href="https://www.dealercurator.com/terms.html" target="_blank"><strong>Terms & Conditions</strong></a>
      and acknowledge our
      <a href="https://www.dealercurator.com/privacy.html" target="_blank"><strong>Privacy Policy</strong></a>.
    </p>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || registerForm.invalid"
          [class.btn-pulse]="submitted">
    Create Account
  </button>
</form>

<section class="another-action" aria-label="Sign in">
  Already have an account? <a class="text-link" [routerLink]="['/login']">Log in</a>
</section>
