import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngxs/store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  /**
   * Intercept errors from the API and perform actions based on the status code
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if ([401, 403].indexOf(err.status) !== -1) {
        // auto logout if 401 response returned from api
        // this.store.dispatch(new Logout());
        // location.reload(true);
      }
      const defaultMsg = 'An unknown error occurred';

      let error = err && err.error ? (err.error.message ? err.error.message : (err.statusText ? err.statusText : defaultMsg)) : defaultMsg;
      error = {
        status: err.status,
        message: error
      };
      return throwError(error);
    }));
  }
}
