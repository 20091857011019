import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {environment} from '@env';

declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled = true;

  constructor(private location: Location, private router: Router, private analytics: AngularFireAnalytics) {
    // Only enable analytics if the environment is not local
    if (environment.local) {
      this.enabled = false;
    } else {
      this.enabled = true;
    }
  }

  /**
   * Track an analytical event by the event name and pass in optional parameters like store state for debugging
   * @param eventName
   * @param params
   */
  trackEvent(eventName: string, params?: {}) {
    if (this.enabled) {
      return this.analytics.logEvent(eventName, params);
    }
  }

  /**
   * Set user properties for an app session
   * @param properties
   */
  setUserProperties(properties: {}) {
    if (this.enabled) {
      return this.analytics.setUserProperties(properties);
    }
  }
}
