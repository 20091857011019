import {State, Action, Selector, StateContext} from '@ngxs/store';
import {LoggerService} from '@app/service/logger/logger.service';
import {ToastService} from '@app/service/util/toast.service';
import {
  CreateReport,
  CreateSearch, DeleteReport, EditReport, GetPastAnalysis, GetReportsByStore,
  GetSearchQueriesByStore
} from '@shared/state/vdp-analysis/vdp-analysis.actions';
import {VdpAnalysisService} from '@shared/service/vdp-analysis.service';
import {ServerResponse} from '@shared/model';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {RoutingService} from "@app/service/routing.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {OnDestroy} from "@angular/core";

export interface VDPAnalysisStateModel {
  vdpSearchData: {
    [k: string]: any; // Organize the search results by store and use the searchID as a primary key
  };
  vdpReportData: {
    [k: string]: any; // Organize the reports by store and use the reportID as a primary key
  };
  searchQueryData: {
    [k: string]: any; // Organize the search queries by store and searchID
  };
  searchesLeftData: {
    [k: string]: number; // Get the search count left for each store as the key
  }
}

@State<VDPAnalysisStateModel>({
  name: 'vdp_analysis',
  defaults: {
    vdpSearchData: {},
    vdpReportData: {},
    searchQueryData: {},
    searchesLeftData: {},
  }
})
export class VdpAnalysisState implements OnDestroy {
  constructor(private vdpAnalysisService: VdpAnalysisService, private logger: LoggerService,
              private toastr: ToastService, private analytics: AnalyticsService, private router: RoutingService,
              private activeRoute: ActivatedRoute) {
  }

  @Selector()
  static getVdpSearchData(state: VDPAnalysisStateModel) {
    return state.vdpSearchData;
  }

  @Selector()
  static getVdpReportData(state: VDPAnalysisStateModel) {
    return state.vdpReportData;
  }

  @Selector()
  static getSearchesLeftData(state: VDPAnalysisStateModel) {
    return state.searchesLeftData;
  }

  @Selector()
  static getSearchQueryData(state: VDPAnalysisStateModel) {
    return state.searchQueryData;
  }

  @Action(CreateSearch)
  createVdpSearch({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: CreateSearch) {
    return this.vdpAnalysisService.createVdpSearch(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message, response.subtext);
        const state = getState();
        const storeID = payload.storeID;

        const searchResult = response.body.result;
        const searchResultCount = response.body.resultCount;
        const searchesLeft = response.body.searchesLeft;
        const searchID = response.body.searchID;
        const searchQuery = response.body.searchQuery;

        let vdpSearchData = state.vdpSearchData;
        let searchesLeftData = state.searchesLeftData;
        let searchQueryData = state.searchQueryData;

        if (!searchResultCount) {
          return;
        }
        this.handleSearchAction({
          storeID,
          searchID
        });

        let resultByStore = vdpSearchData[storeID];
        if (!resultByStore) {
          resultByStore = {};
        }
        resultByStore = Object.assign({}, resultByStore, {
          [searchID]: {
            id: searchID,
            result: searchResult,
            count: searchResultCount
          }
        });
        vdpSearchData = Object.assign({}, vdpSearchData, {
          [storeID]: resultByStore
        });
        searchesLeftData = Object.assign({}, searchesLeftData, {
          [storeID]: searchesLeft
        });

        let searchesByStore = searchQueryData[storeID];
        if (!searchesByStore) {
          searchesByStore = {};
        }
        searchesByStore = Object.assign({}, searchesByStore, {
          [searchID]: {
            ...searchesByStore[searchID],
            ...searchQuery
          }
        });
        searchQueryData = Object.assign({}, searchQueryData, {
          [storeID]: searchesByStore
        });
        patchState({
          vdpSearchData,
          searchesLeftData,
          searchQueryData
        });
        return this.analytics.trackEvent('create_vdp_search');
      })
      .catch(error => {
        this.analytics.trackEvent('create_vdp_search_error', {
          message: error.message,
          payload: payload,
          state: getState()
        });
        this.toastr.error(error.message);
        this.logger.error('Create VDP Search Error', error.message);
      });
  }

  @Action(CreateReport)
  createVdpReport({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: CreateReport) {
    return this.vdpAnalysisService.createVdpReport(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = payload.storeID;

          this.appendReportData(response, state, patchState)

          // const reportID = response.body.reportID;
          // const report = response.body.report;
          //
          // let vdpReportData = state.vdpReportData;
          //
          // let resultByStore = vdpReportData[storeID];
          // if (!resultByStore) {
          //   resultByStore = {};
          // }
          // resultByStore = Object.assign({}, resultByStore, {
          //   [reportID]: {
          //     id: reportID,
          //     report
          //   }
          // });
          // vdpReportData = Object.assign({}, vdpReportData, {
          //   [storeID]: resultByStore
          // });
          // patchState({
          //   vdpReportData
          // });
          return this.analytics.trackEvent('create_vdp_report');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('create_vdp_report_error', {
          message: error.message,
          payload: payload,
          state: getState()
        });
        this.toastr.error(error.message);
        this.logger.error('Create VDP Report Error', error.message);
      });
  }

  appendReportData(response, state, patchState) {
    const storeID = response.body.storeID;
    if (!storeID) {
      return;
    }
    let vdpReportData = state.vdpReportData;
    if (!Object.keys(vdpReportData)[storeID]) {
      vdpReportData = Object.assign({}, vdpReportData, {[storeID]: vdpReportData[storeID] ? vdpReportData[storeID] : []});
    }

    vdpReportData = Object.assign({}, vdpReportData, {[storeID]: vdpReportData[storeID].concat(response.body.report)});

    patchState({
      vdpReportData
    });
  }

  @Action(GetSearchQueriesByStore)
  getPastSearchesByStore({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: GetSearchQueriesByStore) {
    return this.vdpAnalysisService.getSearchesByStoreID(payload.storeID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        const state = getState();
        let searchQueryData = state.searchQueryData;
        searchQueryData = Object.assign({}, searchQueryData, {
          [payload.storeID]: result
        });

        patchState({
          searchQueryData
        });
        return this.analytics.trackEvent('get_vdp_search_queries_by_store');
      });
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @Action(GetPastAnalysis)
  getPastSearchData({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: GetPastAnalysis) {
    const storeID = payload.storeID;
    const searchID = payload.searchID;
    return this.vdpAnalysisService.getVdpSearchResult(storeID, searchID)
      .then((result) => {
        const state = getState();
        let vdpSearchData = state.vdpSearchData;
        const pastSearchData = result.val();

        if (!pastSearchData) {
          return this.analytics.trackEvent('get_past_vdp_search');
        }

        let resultByStore = vdpSearchData[storeID];
        if (!resultByStore) {
          resultByStore = {};
        }
        resultByStore = Object.assign({}, resultByStore, {
          [searchID]: {
            id: searchID,
            result: pastSearchData.result,
            count: pastSearchData.count
          }
        });
        vdpSearchData = Object.assign({}, vdpSearchData, {
          [storeID]: resultByStore
        });

        this.toastr.success('Retrieved previous results for query');

        patchState({
          vdpSearchData,
        });
        return this.analytics.trackEvent('get_past_vdp_search');
      })
      .catch(error => {
        this.analytics.trackEvent('get_past_vdp_search_error', {
          message: error.message,
          payload: payload,
          state: getState()
        });
        this.toastr.error(error.message);
        this.logger.error('Get Past VDP Search Error', error.message);
      });
  }

  handleSearchAction(searchObj) {
    return this.router.navigateToPath([], {
      ...searchObj
    }, {
      relativeTo: this.activeRoute,
      replaceUrl: true
    });
  }

  @Action(GetReportsByStore)
  getReportsData({getState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: GetReportsByStore) {
    const storeID = payload.storeID;
    return this.vdpAnalysisService.getReportsByStoreID(storeID).pipe(tap((result) => {
      if (!result) {
        return;
      }
      const state = getState();
      let vdpReportData = state.vdpReportData;

      vdpReportData = {
        ...vdpReportData,
        [storeID]: result
      };

      patchState({
        vdpReportData,
      });
      this.analytics.trackEvent('get_vdp_reports', {
        storeID,
        count: result.length
      });
    }));
  }

  @Action(EditReport)
  editReport({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: EditReport) {
    return this.vdpAnalysisService.editVdpReport(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          const reportID = response.body.reportID;
          if (!storeID) {
            return;
          }
          let vdpReportData = state.vdpReportData;
          if (!Object.keys(vdpReportData)[storeID]) {
            vdpReportData = Object.assign({}, vdpReportData, {[storeID]: vdpReportData[storeID] ? vdpReportData[storeID] : []});
          }

          const updatedReportsInfo = vdpReportData[storeID]
            .filter(report => report.id !== reportID)
            .concat(response.body.report);

          vdpReportData = Object.assign({}, vdpReportData, {[storeID]: updatedReportsInfo});

          patchState({
            vdpReportData
          });
          return this.analytics.trackEvent('edit_vdp_report');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_vdp_report_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to edit ${payload.name} report`, error.message);
        this.logger.error('Edit VDP Report Error', error);
      });
  }

  @Action(DeleteReport)
  deleteReport({getState, setState, patchState}: StateContext<VDPAnalysisStateModel>, {payload}: DeleteReport) {
    return this.vdpAnalysisService.deleteVdpReport(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const deletedStoreID = response.body.storeID;
          const deletedReportID = response.body.reportID;
          if (!deletedReportID) {
            return;
          }
          let vdpReportData = state.vdpReportData;

          const updatedReportInfo = vdpReportData[deletedStoreID]
            .filter(alarm => alarm.id !== deletedReportID);

          vdpReportData = Object.assign({}, vdpReportData, {[deletedStoreID]: updatedReportInfo});

          patchState({
            vdpReportData
          });
          return this.analytics.trackEvent('delete_vdp_report');
        }
      });
  }
}
