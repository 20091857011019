import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import {AuthState} from '@shared/state/auth/auth.state';
import {FeedAlarm, StoreFeed} from '@shared/model';
import {map, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getStoreFeedsData(storeID): Observable<StoreFeed[]> {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const feedPath = `orgs/${orgID}/stores/${storeID}/feeds`;

    if (!userID || !orgID) {
      return of([]);
    }
    return this.firestore.collection<StoreFeed>(feedPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('name');
      return query;
    }).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(documents => {
        const feedArray: StoreFeed[] = [];
        documents.forEach(doc => {
          const store = doc.payload.doc.data();
          const feedID = doc.payload.doc.id;
          feedArray.push({
            id: feedID,
            ...store
          });
        });
        return feedArray.reverse();
      })
    );
  }

  getFeedByID(feedID, storeID): Observable<StoreFeed> {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const feedPath = `orgs/${orgID}/stores/${storeID}/feeds`;

    if (!userID || !orgID) {
      return of(null);
    }
    return this.firestore.collection<StoreFeed>(feedPath).doc(feedID).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(document => {
        const feedData: any = document.payload.data();
        const feedID = document.payload.id;
        return {
          id: feedID,
          ...feedData
        };
      })
    );
  }

  deleteFeed(storeID, feedID) {
    return this.http.post(this.getUrl('feeds/delete-feed'), {
      storeID, feedID
    }).toPromise();
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  createFeed(data) {
    return this.http.post(this.getUrl('feeds/create-feed'), data)
      .toPromise();
  }

  editFeed(data) {
    return this.http.post(this.getUrl('feeds/edit-feed'), data)
      .toPromise();
  }

  getStoreAlarmsData(storeID): Observable<FeedAlarm[]> {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const alarmPath = `orgs/${orgID}/stores/${storeID}/feedAlarms`;

    if (!userID || !orgID) {
      return of([]);
    }
    return this.firestore.collection<FeedAlarm>(alarmPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('name');
      return query;
    }).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(documents => {
        const alarmArray: FeedAlarm[] = [];
        documents.forEach(doc => {
          const alarm = doc.payload.doc.data();
          const alarmID = doc.payload.doc.id;
          alarmArray.push({
            id: alarmID,
            ...alarm
          });
        });
        return alarmArray.reverse();
      })
    );
  }

  createFeedAlarm(data) {
    return this.http.post(this.getUrl('feeds/create-feed-alarm'), data)
      .toPromise();
  }

  editFeedAlarm(data) {
    return this.http.post(this.getUrl('feeds/edit-feed-alarm'), data)
      .toPromise();
  }

  deleteFeedAlarm(data) {
    return this.http.post(this.getUrl('feeds/delete-feed-alarm'), data)
      .toPromise();
  }
}
