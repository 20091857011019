import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  currentTheme: BehaviorSubject<string>;

  constructor() {
    // Get the current theme based on what was saved in the storage
    this.currentTheme = new BehaviorSubject<string>(localStorage.getItem('currentTheme'));
  }

  /**
   * Set the theme and update the local storage item with the current theme value
   * @param currentTheme
   */
  setCurrentTheme(currentTheme: string) {
    this.currentTheme.next(currentTheme);
    localStorage.setItem('currentTheme', this.currentTheme.value.toString());
  }

  /**
   * Get the current theme from the observable currentTheme
   */
  getCurrentTheme(): Observable<string> {
    return this.currentTheme;
  }
}
