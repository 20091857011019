import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {Login, LoginFailure, Register} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'dlr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './../auth.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  messages: string[];
  returnUrl: string;
  submitted: boolean;
  loading = false;
  loginForm: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private logger: LoggerService,
              private router: RoutingService, private store: Store) {
    this.messages = [];
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.submitted = false;

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get f() {
    return this.loginForm.controls;
  }

  login(): any {
    this.messages = [];
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const data = this.loginForm.value;
    return this.store.dispatch(new Login({email: data.email, password: data.password}))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.loading = false;
        this.submitted = false;
      });
  }
}
