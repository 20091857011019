import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AuthRoutingModule} from '@modules/auth/auth.routing';
import {SharedModule} from '@shared/shared.module';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '@shared/state/auth/auth.state';
import {AuthGuard} from '@app/guard/auth.guard';
import {AuthStateGuard} from '@app/guard/auth-state.guard';
import {NbSpinnerModule} from '@nebular/theme';

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotPasswordComponent],
  imports: [
    SharedModule,
    NgxsModule.forFeature([AuthState]),
    AuthRoutingModule
  ],
  providers: [AuthGuard, AuthStateGuard]
})
export class AuthModule {
}
