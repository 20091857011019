export const isAdmin = (userRole: string): boolean => {
  return userRole && ['admin', 'owner', 'superuser'].includes(userRole);
};

export const isOwner = (userRole: string): boolean => {
  return userRole && ['owner', 'superuser'].includes(userRole);
};

export const isUser = (userRole: string): boolean => {
  return userRole && ['admin', 'owner', 'superuser', 'user'].includes(userRole);
};

export const isReadOnly = (userRole: string): boolean => {
  return userRole && 'readonly' === userRole;
};
