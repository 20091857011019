import { Injectable } from '@angular/core';

import { LogPublisher, LogConsole, LogLocalStorage, LogWebApi } from './publishers';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from "@ngxs/store";

const PUBLISHERS_SETTINGS = [
  {
    loggerName: 'console',
    loggerLocation: '',
    isActive:  false
  },
  {
    loggerName: 'localstorage',
    loggerLocation: 'logging',
    isActive: true
  },
  {
    loggerName: 'webapi',
    loggerLocation: '/api/log',
    isActive: true
  }
];

// ****************************************************
// Log Publisher Config Definition Class
// ****************************************************
class LogPublisherConfig {
  loggerName: string;
  loggerLocation: string;
  isActive: boolean;
}

// ****************************************************
// Logging Publishers Service Class
// ****************************************************
@Injectable()
export class LogPublishersService {
  constructor(private http: HttpClient, private store: Store) {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // *************************
  // Public methods
  // *************************
  // Build publishers array
  buildPublishers(): void {
    let logPub: LogPublisher;

    this.getLoggers().subscribe(response => {
      for (const pub of response.filter(p => p.isActive)) {
        switch (pub.loggerName.toLowerCase()) {
          case 'console':
            logPub = new LogConsole();
            break;
          case 'localstorage':
            logPub = new LogLocalStorage();
            break;
          case 'webapi':
            logPub = new LogWebApi(this.http, this.store);
            break;
        }
        // Set location of logging
        logPub.location = pub.loggerLocation;
        // Add publisher to array
        this.publishers.push(logPub);
      }
    });
  }

  // Get logger configuration info from JSON file
  getLoggers(): Observable<LogPublisherConfig[]> {
    return of(PUBLISHERS_SETTINGS).pipe(catchError(this.handleErrors));
  }

  // *************************
  // Private methods
  // *************************
  private handleErrors(error: any): Observable<any> {
    const errors: string[] = [];
    let msg = '';

    msg = 'Status: ' + error.status;
    msg += ' - Status Text: ' + error.statusText;
    console.log(error);
    if (error.json()) {
      msg += ' - Exception Message: ' + error.json().exceptionMessage;
    }
    errors.push(msg);

    console.error('An error occurred', errors);

    return throwError(errors);
  }
}
