import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AuthStateGuard} from './guard/auth-state.guard';
import {AuthGuard} from './guard/auth.guard';
import {throwIfAlreadyLoaded} from './guard/module-import.guard';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {LogPublishersService} from '@app/service/logger/log-publishers.service';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    AuthGuard,
    AuthStateGuard,
    AnalyticsService,
    LoggerService,
    LogPublishersService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
