import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import {AuthState} from '@shared/state/auth/auth.state';
import {StoreFeed} from '@shared/model';
import {map, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class VdpAnalysisService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getSearchesByStoreID(storeID): Observable<any> {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const searchPath = `orgs/${orgID}/stores/${storeID}/vdpSearches`;

    if (!orgID) {
      return of(null);
    }
    return this.firestore.collection<any>(searchPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('lastUsedAt', 'desc');
      query = query.limit(10);
      return query;
    }).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(documents => {
        const searches = {};
        documents.forEach(doc => {
          const id = doc.payload.doc.id;
          searches[id] = {
            id,
            ...doc.payload.doc.data()
          };
        });
        return searches;
      })
    );
  }

  getReportsByStoreID(storeID): Observable<any> {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const searchPath = `orgs/${orgID}/stores/${storeID}/vdpReports`;

    if (!orgID) {
      return of(null);
    }
    return this.firestore.collection<any>(searchPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('updatedAt', 'desc');
      return query;
    }).snapshotChanges().pipe(
      take(1), // If the reads start getting expensive
      map(documents => {
        const reports = [];
        documents.forEach(doc => {
          reports.push({
            id: doc.payload.doc.id,
            ...doc.payload.doc.data()
          });
        });
        return reports;
      })
    );
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  createVdpSearch(data) {
    return this.http.post(this.getUrl('vdp-analysis/create-search'), data)
      .toPromise();
  }

  createVdpReport(data) {
    return this.http.post(this.getUrl('vdp-analysis/create-report'), data)
      .toPromise();
  }

  editVdpReport(data) {
    return this.http.post(this.getUrl('vdp-analysis/edit-report'), data)
      .toPromise();
  }

  deleteVdpReport(data) {
    return this.http.post(this.getUrl('vdp-analysis/delete-report'), data)
      .toPromise();
  }

  getVdpSearchResult(storeID: string, searchID: string) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.databaseService.database.ref(`vdpSearchData/${orgID}/${storeID}/${searchID}`)
      .once('value');
  }

  getVdpReportResult(storeID: string, reportID: string) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.databaseService.database.ref(`vdpReportData/${orgID}/${storeID}/${reportID}`)
      .once('value');
  }
}
