import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {FeedAlarm, ServerResponse, StoreFeed} from '@shared/model';
import {tap} from 'rxjs/operators';
import {ToastService} from '@app/service/util/toast.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {FeedService} from '@shared/service/feed.service';
import {
  AddFeed,
  AddFeedAlarm,
  DeleteFeed, DeleteFeedAlarm,
  EditFeed, EditFeedAlarm,
  GetAFeed,
  GetFeedAlarms,
  GetStoreFeeds
} from '@shared/state/feed/feed.actions';
import {AnalyticsService} from '@app/service/util/analytics.service';

/**
 * Store the feeds under the storeID for feedInfoData
 * Use the storeIDs field to store storeIDs that were already retrieved
 */
export interface FeedStateModel {
  feedInfoData: {
    [k: string]: Partial<StoreFeed>[]
  };
  feedIDs: {
    [k: string]: string[]
  };
  feedAlarms: {
    [k: string]: Partial<FeedAlarm>[]; // All the feed alarms ina  store
  };
  storeIDs: string[];
}

@State<FeedStateModel>({
  name: 'feed',
  defaults: {
    feedInfoData: {},
    feedIDs: {},
    feedAlarms: {},
    storeIDs: []
  }
})
export class FeedState {
  constructor(private feedService: FeedService, private toastr: ToastService, private store: Store,
              private routingService: RoutingService, private logger: LoggerService, private analytics: AnalyticsService) {
  }

  @Selector()
  static getFeedInfoData(state: FeedStateModel) {
    return state.feedInfoData;
  }

  @Selector()
  static getFeedIDs(state: FeedStateModel) {
    return state.feedIDs;
  }

  @Selector()
  static getRetrievedStoreIDs(state: FeedStateModel) {
    return state.storeIDs;
  }

  @Selector()
  static getFeedAlarms(state: FeedStateModel) {
    return state.feedAlarms;
  }

  @Action(AddFeed)
  addFeed({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: AddFeed) {
    return this.feedService.createFeed(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();

          this.appendFeedData(response, state, patchState);
        }
        return this.analytics.trackEvent('add_feed');
      })
      .catch(error => {
        this.analytics.trackEvent('add_feed_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to create ${payload.name}`, error.message);
        this.logger.error('Feed Error', error);
      });
  }

  @Action(DeleteFeed)
  deleteFeed({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: DeleteFeed) {
    return this.feedService.deleteFeed(payload.storeID, payload.feedID)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const deletedStoreID = response.body.storeID;
          const deletedFeedID = response.body.feedID;
          if (!deletedStoreID) {
            return;
          }
          let feedIDs = state.feedIDs;
          let feedInfoData = state.feedInfoData;

          const updatedFeeds = feedIDs[deletedStoreID]
            .filter(feedID => feedID !== deletedFeedID);
          const updatedFeedInfo = feedInfoData[deletedStoreID]
            .filter(feed => feed.id !== deletedFeedID);

          feedIDs = Object.assign({}, feedIDs, {[deletedStoreID]: updatedFeeds});
          feedInfoData = Object.assign({}, feedInfoData, {[deletedStoreID]: updatedFeedInfo});

          patchState({
            feedIDs,
            feedInfoData
          });
          return this.analytics.trackEvent('delete_feed');
        }
      });
  }

  @Action(EditFeed)
  editFeed({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: AddFeed) {
    return this.feedService.editFeed(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          if (!storeID) {
            return;
          }
          let feedIDs = state.feedIDs;
          // Needed because an error "object is not extensible" is thrown if the object does not exist yet.
          if (!Object.keys(feedIDs)[storeID]) {
            feedIDs = Object.assign({}, feedIDs, {[storeID]: feedIDs[storeID] ? feedIDs[storeID] : []});
          }
          let feedInfoData = state.feedInfoData;
          if (!Object.keys(feedInfoData)[storeID]) {
            feedInfoData = Object.assign({}, feedInfoData, {[storeID]: feedInfoData[storeID] ? feedInfoData[storeID] : []});
          }

          const updatedFeeds = feedIDs[storeID]
            .filter(feedID => feedID !== response.body.id)
            .concat(response.body.feedID);
          const updatedFeedInfo = feedInfoData[storeID]
            .filter(feed => feed.id !== response.body.id)
            .concat(response.body);

          feedIDs = Object.assign({}, feedIDs, {[storeID]: updatedFeeds});
          feedInfoData = Object.assign({}, feedInfoData, {[storeID]: updatedFeedInfo});

          patchState({
            feedIDs,
            feedInfoData
          });
          return this.analytics.trackEvent('edit_feed');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_feed_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to edit ${payload.name}`, error.message);
        this.logger.error('Edit Feed Error', error);
      });
  }

  appendFeedData(response, state, patchState) {
    const storeID = response.body.storeID;
    if (!storeID) {
      return;
    }
    let feedIDs = state.feedIDs;
    // Needed because an error "object is not extensible" is thrown if the object does not exist yet.
    if (!Object.keys(feedIDs)[storeID]) {
      feedIDs = Object.assign({}, feedIDs, {[storeID]: feedIDs[storeID] ? feedIDs[storeID] : []});
    }
    let feedInfoData = state.feedInfoData;
    if (!Object.keys(feedInfoData)[storeID]) {
      feedInfoData = Object.assign({}, feedInfoData, {[storeID]: feedInfoData[storeID] ? feedInfoData[storeID] : []});
    }

    feedIDs = Object.assign({}, feedIDs, {[storeID]: feedIDs[storeID].concat(response.body.feedID)});
    feedInfoData = Object.assign({}, feedInfoData, {[storeID]: feedInfoData[storeID].concat(response.body)});

    patchState({
      feedIDs,
      feedInfoData
    });
  }

  @Action(GetStoreFeeds)
  getFeedData({getState, patchState}: StateContext<FeedStateModel>, {payload}: GetStoreFeeds) {
    const storeID = payload.storeID;
    return this.feedService.getStoreFeedsData(storeID).pipe(tap((result) => {
      if (!result) {
        return;
      }
      const state = getState();
      let feedIDs = state.feedIDs;
      let feedInfoData = state.feedInfoData;

      feedIDs = {
        ...feedIDs,
        [storeID]: result.map(val => val.id)
      };
      feedInfoData = {
        ...feedInfoData,
        [storeID]: result
      };

      patchState({
        feedInfoData,
        feedIDs,
        storeIDs: state.storeIDs.filter(storedID => storedID !== storeID).concat(storeID)
      });
      this.analytics.trackEvent('get_feeds', {
        count: result.length
      });
    }));
  }

  @Action(GetFeedAlarms)
  getAlarmsData({getState, patchState}: StateContext<FeedStateModel>, {payload}: GetFeedAlarms) {
    const storeID = payload.storeID;
    return this.feedService.getStoreAlarmsData(storeID).pipe(tap((result) => {
      if (!result) {
        return;
      }
      const state = getState();
      let feedAlarms = state.feedAlarms;

      feedAlarms = {
        ...feedAlarms,
        [storeID]: result
      };

      patchState({
        feedAlarms,
      });
      this.analytics.trackEvent('get_feed_alarms', {
        count: result.length
      });
    }));
  }

  @Action(GetAFeed)
  getFeedByID({getState, patchState}: StateContext<FeedStateModel>, {payload}: GetAFeed) {
    const feedID = payload.feedID;
    const storeID = payload.storeID;
    return this.feedService.getFeedByID(feedID, storeID).pipe(
      tap((result) => {
        if (!result) {
          return;
        }
        const state = getState();
        let allFeedIDs = state.feedIDs;
        let allFeedInfoData = state.feedInfoData;
        let feedIDs = allFeedIDs[storeID] ? allFeedIDs[storeID] : [];
        let feedInfoData = allFeedInfoData[storeID] ? allFeedInfoData[storeID] : [];

        allFeedIDs = {
          ...allFeedIDs,
          [storeID]: feedIDs
            .filter(feedID => feedID !== result.id).concat(feedID)
        };
        allFeedInfoData = {
          ...allFeedInfoData,
          [storeID]: feedInfoData
            .filter(feedData => feedID !== feedData.id).concat(result)
        };

        patchState({
          feedIDs: allFeedIDs,
          feedInfoData: allFeedInfoData,
          storeIDs: state.storeIDs.filter(storedID => storedID !== storeID).concat(storeID)
        });
        this.analytics.trackEvent('get_a_feed', {
          feedID: result.id
        });
      }));
  }

  @Action(AddFeedAlarm)
  addFeedAlarm({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: AddFeedAlarm) {
    return this.feedService.createFeedAlarm(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();

          this.appendFeedAlarmData(response, state, patchState);
        }
        return this.analytics.trackEvent('add_feed_alarm');
      })
      .catch(error => {
        this.analytics.trackEvent('add_feed_alarm_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to create ${payload.name}`, error.message);
        this.logger.error('Feed Alarm Error', error);
      });
  }

  appendFeedAlarmData(response, state, patchState) {
    const storeID = response.body.storeID;
    if (!storeID) {
      return;
    }
    let feedAlarms = state.feedAlarms;
    if (!Object.keys(feedAlarms)[storeID]) {
      feedAlarms = Object.assign({}, feedAlarms, {[storeID]: feedAlarms[storeID] ? feedAlarms[storeID] : []});
    }

    feedAlarms = Object.assign({}, feedAlarms, {[storeID]: feedAlarms[storeID].concat(response.body)});

    patchState({
      feedAlarms
    });
  }

  @Action(EditFeedAlarm)
  editFeedAlarm({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: EditFeedAlarm) {
    return this.feedService.editFeedAlarm(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          if (!storeID) {
            return;
          }
          let feedAlarms = state.feedAlarms;
          if (!Object.keys(feedAlarms)[storeID]) {
            feedAlarms = Object.assign({}, feedAlarms, {[storeID]: feedAlarms[storeID] ? feedAlarms[storeID] : []});
          }

          const updatedAlarmsInfo = feedAlarms[storeID]
            .filter(alarm => alarm.id !== response.body.id)
            .concat(response.body);

          feedAlarms = Object.assign({}, feedAlarms, {[storeID]: updatedAlarmsInfo});

          patchState({
            feedAlarms
          });
          return this.analytics.trackEvent('edit_feed_alarm');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_feed_alarm_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to edit ${payload.name} alarm`, error.message);
        this.logger.error('Edit Feed Alarm Error', error);
      });
  }

  @Action(DeleteFeedAlarm)
  deleteFeedAlarm({getState, setState, patchState}: StateContext<FeedStateModel>, {payload}: DeleteFeedAlarm) {
    return this.feedService.deleteFeedAlarm(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const deletedStoreID = response.body.storeID;
          const deletedAlarmID = response.body.alarmID;
          if (!deletedAlarmID) {
            return;
          }
          let feedAlarms = state.feedAlarms;

          const updatedAlarmInfo = feedAlarms[deletedStoreID]
            .filter(alarm => alarm.id !== deletedAlarmID);

          feedAlarms = Object.assign({}, feedAlarms, {[deletedStoreID]: updatedAlarmInfo});

          patchState({
            feedAlarms
          });
          return this.analytics.trackEvent('delete_feed_alarm');
        }
      });
  }
}
