import {CuratorAlert, CuratorUser, Org} from '@shared/model';
import * as firebase from 'firebase/app';
import 'firebase/auth';

export enum ActionTypes {
  GET_USER = '[Auth] GetUser',
  CHECK_SESSION = '[Auth] CheckSession',
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] LoginSuccess',
  LOGIN_FAILURE = '[Auth] LoginFailure',
  LOGIN_REDIRECT = '[Auth] LoginRedirect',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] LogoutSuccess',
  REGISTER = '[Auth] Register',
  CREATE_USER = '[Auth] CreateUser',
  EDIT_USER = '[Auth] EditUser',
  RESET_PASSWORD = '[Auth] ResetPassword',
  REFRESH_TOKEN = '[Auth] RefreshToken',
  GET_ORG = '[Auth] GetOrg',
  EDIT_ORG = '[Auth] EditOrg',
  DELETE_FB_TOKEN = '[Auth] DeleteFbToken',
  LINK_FACEBOOK = '[Auth] LinkFacebook',
  GET_FB_BUSINESS_INFO = '[Auth] GetFBBusinessInfo',
  WRITE_FB_BUSINESS_INFO = '[Auth] WriteFBBusinessInfo',
}

export class GetUser {
  static readonly type = ActionTypes.GET_USER;

  constructor() {
  }
}

export class GetOrg {
  static readonly type = ActionTypes.GET_ORG;

  constructor() {
  }
}

export class CheckSession {
  static readonly type = ActionTypes.CHECK_SESSION;

  constructor() {
  }
}

export class Login {
  static readonly type = ActionTypes.LOGIN;

  constructor(public payload: { email: string, password: string }) {
  }
}

export class LoginSuccess {
  static readonly type = ActionTypes.LOGIN_SUCCESS;

  constructor(public payload: firebase.User) {
  }
}

export class LoginFailure {
  static readonly type = ActionTypes.LOGIN_FAILURE;

  // Payload is error object
  constructor(public payload: CuratorAlert | any) {
  }
}

export class LoginRedirect {
  static readonly type = ActionTypes.LOGIN_REDIRECT;

  constructor(public redirectUrl: any) {
  }
}

export class Logout {
  static readonly type = ActionTypes.LOGOUT;

  constructor() {
  }
}

export class LogoutSuccess {
  static readonly type = ActionTypes.LOGOUT_SUCCESS;

  constructor() {
  }
}

export class Register {
  static readonly type = ActionTypes.REGISTER;

  constructor(public payload: { email: string, password: string, [k: string]: any}) {
  }
}

export class CreateUser {
  static readonly type = ActionTypes.CREATE_USER;

  constructor(public payload: Partial<CuratorUser>) {
  }
}

export class EditUser {
  static readonly type = ActionTypes.EDIT_USER;

  constructor(public payload: Partial<CuratorUser>) {
  }
}

export class ResetPassword {
  static readonly type = ActionTypes.RESET_PASSWORD;

  constructor(public payload: { email: string}) {
  }
}

export class RefreshToken {
  static readonly type = ActionTypes.REFRESH_TOKEN;

  constructor() {
  }
}

export class EditOrg {
  static readonly type = ActionTypes.EDIT_ORG;
  constructor(public payload: Partial<Org>) {}
}


export class DeleteFbToken {
  static readonly type = ActionTypes.DELETE_FB_TOKEN;

  constructor() {
  }
}

export class LinkFacebook {
  static readonly type = ActionTypes.LINK_FACEBOOK;

  constructor() {
  }
}

export class GetFbBusinessInfo {
  static readonly type = ActionTypes.GET_FB_BUSINESS_INFO;

  constructor() {
  }
}

export class WriteFbBusinessInfo {
  static readonly type = ActionTypes.WRITE_FB_BUSINESS_INFO;

  constructor(public payload: any) {
  }
}
