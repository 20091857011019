import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {environment} from '@env';
import {CuratorAlert} from '@shared/model';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '@shared/state/auth/auth.state';
import {Observable, of, zip} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {StoreState} from '@shared/state/store/store.state';
import {isAdmin} from '@shared/helpers/auth';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import firestore = firebase.firestore;

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  headers: HttpHeaders;
  apiUrl: string;

  @Select(AuthState.getUserID) userID$: Observable<any>;
  @Select(AuthState.getOrgID) orgID$: Observable<any>;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  getAlertData(): Observable<Partial<CuratorAlert[]>> {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const userData = this.store.selectSnapshot(AuthState.getUserData);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    let storeIDs = (this.store.selectSnapshot(StoreState.getStoreIDs) || []).concat('');
    const alertPath = `orgs/${orgID}/alerts`;

    if (storeIDs.length === 1 && userData.stores) {
      storeIDs = [''];
      for (const storeID of Object.keys(userData.stores)) {
        storeIDs.push(storeID);
      }
    }
    if (!userID || !orgID) {
      return of([]);
    }
    const alertSnapshots: Observable<DocumentChangeAction<CuratorAlert>[]>[] = [];
    let start = 0;
    let index = 10;
    do {
      const slicedStoreIDs = storeIDs.slice(start, index);
      start = index;
      index += 10;
      if (storeIDs.length < index) {
        index = storeIDs.length + 1;
      }
      // If the user is an Admin, along with admin alerts, provide alerts for non admins
      if (isAdmin(userData.role)) {
        alertSnapshots.push(this.getSlicedAlerts(alertPath, slicedStoreIDs, isAdmin(userData.role)));
      }
      alertSnapshots.push(this.getSlicedAlerts(alertPath, slicedStoreIDs, false));
    } while (index <= storeIDs.length + 1 && start < index - 1);
    return zip(...alertSnapshots)
      .pipe(
        map(documents => {
          let docs = [];
          documents.forEach(doc => {
            docs = docs.concat(doc);
          });
          return docs;
        }),
        map(documents => {
          const alertArray: CuratorAlert[] = [];
          documents.forEach(doc => {
            alertArray.push({
              id: doc.payload.doc.id,
              ...doc.payload.doc.data()
            });
          });
          return alertArray.reverse();
        })
      );
  }

  getSlicedAlerts(alertPath, storeIDs, isUserAdmin = false) {
    return this.firestore.collection<CuratorAlert>(alertPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`storeID`, 'in', storeIDs);
      query = query.where(`forAdmin`, '==', isUserAdmin);
      query = query.where(`isResolved`, '==', false);
      query = query.orderBy('updatedAt');
      return query;
    }).snapshotChanges();
  }

  createAlert(payload: CuratorAlert) {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const userData = this.store.selectSnapshot(AuthState.getUserData);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const alertPath = `orgs/${orgID}/alerts`;
    const timestamp = moment().format('YYYYMMDDHHmmss');

    if (!isAdmin(userData.role)) {
      return Promise.reject();
    }

    return this.firestore.collection(alertPath).doc(timestamp).update({
      ...payload,
      createdAt: parseInt(timestamp),
      createdBy: userID,
      isResolved: false
    })

  }

  resolveAlert(alertID) {
    return this.http.post(this.getUrl('alerts/resolve-alert'), {
      alertID
    })
      .toPromise();
  }
}
