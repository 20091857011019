import {CuratorAlert} from '@shared/model';

export enum ActionTypes {
  GET_ALERTS = '[Alert] GetAlerts',
  ADD_ALERT = '[Alert] Add',
  RESOLVE_ALERT = '[Alert] Resolve'
}

export class AddAlerts {
  static readonly type = ActionTypes.ADD_ALERT;
  constructor(public payload: CuratorAlert) {}
}

export class GetAlerts {
  static readonly type = ActionTypes.GET_ALERTS;
  constructor() {}
}

export class ResolveAlert {
  static readonly type = ActionTypes.RESOLVE_ALERT;
  constructor(public payload: {alertID: string}) {}
}
