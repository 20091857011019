import {VehicleInventory} from '@shared/model';

export enum ActionTypes {
  GET_ALL_INVENTORY = '[Inventory] GetAllInventory',
  GET_INVENTORY_IDS_BY_FEED = '[Inventory] GetInventoryIDsByFeed',
  GET_INVENTORY_BY_ID = '[Inventory] GetInventoryByID',
  GET_INVENTORY_ITEMS = '[Inventory] GetInventoryItems',
  EDIT_INVENTORY_ITEM = '[Inventory] EditInventoryItem',
  DELETE_INVENTORY_ITEM = '[Inventory] DeleteInventoryItem',
  RESET_INVENTORY_ITEM = '[Inventory] ResetInventoryItem',
}

export class GetAllInventory {
  static readonly type = ActionTypes.GET_ALL_INVENTORY;
  constructor(public payload: {storeID: string}) {}
}

export class GetInventoryIDsByFeed {
  static readonly type = ActionTypes.GET_INVENTORY_IDS_BY_FEED;
  constructor(public payload: {storeID: string, feedID: string}) {}
}

export class GetInventoryItems {
  static readonly type = ActionTypes.GET_INVENTORY_ITEMS;
  constructor(public payload: {storeID: string, feedID: string, inventoryKeys: string[]}) {}
}

export class GetInventoryByID {
  static readonly type = ActionTypes.GET_INVENTORY_BY_ID;
  constructor(public payload: {storeID: string, vin: string}) {}
}

export class EditInventoryItem {
  static readonly type = ActionTypes.EDIT_INVENTORY_ITEM;
  constructor(public payload: Partial<VehicleInventory>) {}
}

export class DeleteInventoryItem {
  static readonly type = ActionTypes.DELETE_INVENTORY_ITEM;
  constructor(public payload: { storeID: string, feedID: string, vin: string }) {}
}

export class ResetInventoryItem {
  static readonly type = ActionTypes.RESET_INVENTORY_ITEM;
  constructor(public payload: { storeID: string, feedID: string, vin: string }) {}
}

