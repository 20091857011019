

export enum ActionTypes {
  GET_CATALOGS = '[FacebookCatalog] GetAllCatalogs',
  GET_CATALOG = '[FacebookCatalog] GetACatalog',
  ADD_CATALOG = '[FacebookCatalog] AddCatalog',
  EDIT_CATALOG = '[FacebookCatalog] EditCatalog',
  DELETE_CATALOG = '[FacebookCatalog] DeleteCatalog'
}

export class AddCatalog {
  static readonly type = ActionTypes.ADD_CATALOG;
  constructor(public payload: any) {}
}

export class EditCatalog {
  static readonly type = ActionTypes.EDIT_CATALOG;
  constructor(public payload: any) {}
}

export class DeleteCatalog {
  static readonly type = ActionTypes.DELETE_CATALOG;
  constructor(public payload: any) {}
}

export class GetCatalogs {
  static readonly type = ActionTypes.GET_CATALOGS;
  constructor() {}
}

export class GetACatalog {
  static readonly type = ActionTypes.GET_CATALOG;
  constructor(public payload: any) {}
}
