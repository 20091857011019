// https://rnfirebase.io/docs/v3.1.*/auth/reference/auth#signInWithEmailAndPassword
export type LoginErrorCode =
  | 'auth/invalid-email'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/wrong-password';

// TODO: Could not find documentation on firebase logout error codes, should
// investigate this further.
export type LogoutErrorCode = 'auth/no-current-user' | string;

export type PasswordResetInitializeErrorCode =
  | 'auth/invalid-email'
  | 'auth/user-not-found';

// TODO: Doc link
export type PasswordResetErrorCode =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/week-password';

// https://rnfirebase.io/docs/v3.1.*/auth/reference/auth#createUserWithEmailAndPassword
// TODO: What happens if I create a user with an existing email? What error?
export type CreateUserErrorCode =
  | 'auth/invalid-email'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/wrong-password';

// TODO: Doc link
export type PendingVerificationErrorCode =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-found';

// https://rnfirebase.io/docs/v3.1.*/auth/reference/auth#checkActionCode
export type CheckingVerificationErrorCode =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-disabled';


// Firebase Auth Error
export const authError = (error: any) => {
  if (error && error.message === 'silent') {
    return false;
  }
  let msg = 'Error!';
  const auth = {
    'email-already-in-use': `
				An account with this email address already exists.
				Try to login with this account instead.
			`,
    'user-not-found': `Your account does not exist in Dealer Curator.`,
    'wrong-password': `Invalid password`
  };
  if (error.code === 'auth/email-already-in-use') {
    msg = auth['email-already-in-use'];
  } else if (error.code === 'auth/user-not-found') {
    msg = auth['user-not-found'];
  } else if (error.code === 'auth/wrong-password') {
    msg = auth['wrong-password'];
  } else {
    return error.message || 'Oops, error!';
  }
  return msg;
};
