export enum ActionTypes {
  GET_PAST_ANALYSIS = '[VDP Analysis] GetPastAnalysis',
  CREATE_SEARCH = '[VDP Analysis] CreateSearch',
  CREATE_REPORT = '[VDP Analysis] CreateReport',
  EDIT_REPORT = '[VDP Analysis] EditReport',
  DELETE_REPORT = '[VDP Analysis] DeleteReport',
  GET_REPORT = '[VDP Analysis] GetReport',
  GET_REPORTS_BY_STORE = '[VDP Analysis] GetReportByStore',
  GET_SEARCH_QUERIES_BY_STORE = '[VDP Analysis] GetSearchQueriesByStore',
}

export class GetPastAnalysis {
  static readonly type = ActionTypes.GET_PAST_ANALYSIS;

  constructor(public payload: { storeID: string, searchID: string }) {
  }
}

export class GetReport {
  static readonly type = ActionTypes.GET_REPORT;

  constructor(public payload: { storeID: string, reportID: string }) {
  }
}

export class GetReportsByStore {
  static readonly type = ActionTypes.GET_REPORTS_BY_STORE;

  constructor(public payload: { storeID: string }) {
  }
}

export class CreateSearch {
  static readonly type = ActionTypes.CREATE_SEARCH;

  constructor(public payload: any) {
  }
}

export class CreateReport {
  static readonly type = ActionTypes.CREATE_REPORT;

  constructor(public payload: any) {
  }
}

export class EditReport {
  static readonly type = ActionTypes.EDIT_REPORT;

  constructor(public payload: any) {
  }
}

export class DeleteReport {
  static readonly type = ActionTypes.DELETE_REPORT;

  constructor(public payload: { storeID: string; reportID: string; }) {
  }
}

export class GetSearchQueriesByStore {
  static readonly type = ActionTypes.GET_SEARCH_QUERIES_BY_STORE;

  constructor(public payload: { storeID: string }) {
  }
}

