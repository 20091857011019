import {State, Action, Selector, StateContext} from '@ngxs/store';
import {AddAlerts, GetAlerts, ResolveAlert} from './alert.actions';
import {tap} from 'rxjs/operators';
import {CuratorAlert} from '@shared/model';
import {AlertService} from '@shared/service/alert.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {ToastService} from '@app/service/util/toast.service';

export interface AlertStateModel {
  alertInfoData: Partial<CuratorAlert>[];
}

@State<AlertStateModel>({
  name: 'alert',
  defaults: {
    alertInfoData: []
  }
})
export class AlertState {
  constructor(private alertService: AlertService, private logger: LoggerService, private toastr: ToastService) {
  }

  @Selector()
  static getAlertInfoData(state: AlertStateModel) {
    return state.alertInfoData;
  }

  @Action(AddAlerts)
  addAlert({getState, setState}: StateContext<AlertStateModel>, {payload}: AddAlerts) {
    return this.alertService.createAlert(payload);
  }

  @Action(ResolveAlert)
  resolveAlert({getState, setState, patchState}: StateContext<AlertStateModel>, {payload}: ResolveAlert) {
    return this.alertService.resolveAlert(payload.alertID)
      .then(() => {
        let alertInfoData = getState().alertInfoData;
        if (alertInfoData) {
          alertInfoData = alertInfoData.filter(alert => alert.id != payload.alertID);
        }
        patchState({
          alertInfoData
        });
        return this.toastr.success('Alert has been resolved!');
      })
      .catch(err => {
        return this.toastr.error('An error occurred while trying to resolve alert', err);
      });
  }

  @Action(GetAlerts)
  getAlertsData({getState, patchState}: StateContext<AlertStateModel>) {
    return this.alertService.getAlertData()
      .pipe(tap((result) => {
        patchState({
          alertInfoData: result,
        });
      }));
  }
}
