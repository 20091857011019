import {ServerResponse} from '@shared/model';

export const allowedPlatforms = ['inventory', 'facebook', 'vdpAnalysis'];
export const invDependent = ['facebookAds', 'vdpAnalysis', 'adwords'];

export const objectKeys = (obj) => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj);
};


export const splitUp = (arr: any[], n: number) => {
  const rest = arr.length % n;
  let restUsed = rest;
  const partLength = Math.floor(arr.length / n);
  const result = [];

  for (let i = 0; i < arr.length; i += partLength) {
    let end = partLength + i;
    let add = false;

    if (rest !== 0 && restUsed) { // should add one element for the division
      end++;
      restUsed--; // we've used one division element now
      add = true;
    }

    result.push(arr.slice(i, end)); // part of the array

    if (add) {
      i++; // also increment i in the case we added an extra element for division
    }
  }

  return result;
};

export const serverResponseToast = (toastr: any, response: ServerResponse) => {
  const duration = response.subtext ? 13000 : 7000;
  toastr.response(response.type, response.message, response.subtext, duration);
};

export const removeByKey = (myObj: {}, deleteKey) => {
  return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
    }, {});
}
