import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NebularModule} from './nebular.module';
import {MomentModule} from 'ngx-moment';
import {AddFabButtonComponent} from '@shared/components/add-fab-button/add-fab-button.component';
import {ThemeModule} from '../theme/theme.module';
import {MatButtonModule} from '@angular/material/button';
import {PipeModule} from '@shared/pipes/pipe.module';
import {ClipboardModule} from 'ngx-clipboard';
import {LoadingComponent} from '@app/components/loading/loading.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MomentModule,
    ThemeModule,
    MatButtonModule,
    PipeModule, ClipboardModule
  ],
  declarations: [LoadingComponent, AddFabButtonComponent],
  exports: [
    LoadingComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NebularModule,
    MomentModule,
    AddFabButtonComponent, ClipboardModule, PipeModule
  ]
})
export class SharedModule {
}
