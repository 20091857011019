import {StoreData} from '@shared/model';

export enum ActionTypes {
  GET_STORES_BY_URL = '[Store] GetStoresByUrl',
  GET_STORES = '[Store] GetStores',
  GET_STORE = '[Store] GetStore',
  GET_SERVICE = '[Store] GetService',
  GET_ACTIVE_SERVICES = '[Store] GetActiveServices',
  GET_INACTIVE_SERVICES = '[Store] GetInactiveServices',
  DISABLE_ACTIVE_SERVICE = '[Store] DisableActiveService',
  REENABLE_DISABLED_SERVICE = '[Store] ReEnableDisabledService',
  ENABLE_INACTIVE_SERVICES = '[Store] EnableInactiveServices',
  GET_PENDING_SERVICES = '[Store] GetPendingServices',
  REMOVE_PENDING_SERVICE = '[Store] RemovePendingService',
  ADD_PENDING_SERVICE = '[Store] AddPendingService',
  ADD_STORE = '[Store] AddStore',
  EDIT_STORE = '[Store] EditStore'
}

export class AddStore {
  static readonly type = ActionTypes.ADD_STORE;

  constructor(public payload: StoreData) {
  }
}

export class EditStore {
  static readonly type = ActionTypes.EDIT_STORE;

  constructor(public payload: StoreData) {
  }
}

export class GetStores {
  static readonly type = ActionTypes.GET_STORES;

  constructor() {
  }
}

export class GetStoresByUrl {
  static readonly type = ActionTypes.GET_STORES_BY_URL;

  constructor(public payload: any) {
  }
}

export class GetStore {
  static readonly type = ActionTypes.GET_STORE;

  constructor(public payload: string) {
  }
}

export class GetService {
  static readonly type = ActionTypes.GET_SERVICE;

  constructor(public payload: any) {
  }
}

export class GetActiveServices {
  static readonly type = ActionTypes.GET_ACTIVE_SERVICES;

  constructor() {
  }
}

export class GetInactiveServices {
  static readonly type = ActionTypes.GET_INACTIVE_SERVICES;

  constructor() {
  }
}

export class GetPendingServices {
  static readonly type = ActionTypes.GET_PENDING_SERVICES;

  constructor() {
  }
}

export class RemovePendingService {
  static readonly type = ActionTypes.REMOVE_PENDING_SERVICE;

  constructor(public payload: any) {
  }
}

export class DisableActiveService {
  static readonly type = ActionTypes.DISABLE_ACTIVE_SERVICE;

  constructor(public payload: any) {
  }
}

export class ReEnableDisabledService {
  static readonly type = ActionTypes.REENABLE_DISABLED_SERVICE;

  constructor(public payload: any) {
  }
}

export class EnableInactiveServices {
  static readonly type = ActionTypes.ENABLE_INACTIVE_SERVICES;

  constructor(public payload: any) {
  }
}

export class AddPendingService {
  static readonly type = ActionTypes.ADD_PENDING_SERVICE;

  constructor(public payload: any) {
  }
}
