import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {ServerResponse} from '@shared/model';
import {ToastService} from '@app/service/util/toast.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {
  AddCatalog,
  DeleteCatalog,
  EditCatalog,
  GetACatalog, GetCatalogs,
} from "@shared/state/facebook/catalog/catalog.actions";
import {FacebookService} from "@shared/service/facebook.service";
import {BillingStateModel} from "@shared/state/billing/billing.state";
import {DeleteFbToken} from "@shared/state/auth/auth.actions";

/**
 * Store the catalogs under the storeID for catalogData
 * Use the storeIDs field to store storeIDs that were already retrieved
 */
export interface CatalogStateModel {
  catalogData: any[];
  catalogIDs: string[];
  storeIDs: string[];
  loaded: boolean;
  catalogsLoaded: boolean;
}

@State<CatalogStateModel>({
  name: 'facebook_catalog',
  defaults: {
    catalogData: null,
    catalogIDs: [],
    storeIDs: [],
    loaded: false,
    catalogsLoaded: false
  }
})
export class FacebookCatalogState {
  constructor(private toastr: ToastService, private store: Store, private routingService: RoutingService,
              private logger: LoggerService, private analytics: AnalyticsService,
              private facebookService: FacebookService) {
  }

  @Selector()
  static getCatalogData(state: CatalogStateModel) {
    return state.catalogData;
  }

  @Selector()
  static getCatalogIDs(state: CatalogStateModel) {
    return state.catalogIDs;
  }

  @Selector()
  static getRetrievedStoreIDs(state: CatalogStateModel) {
    return state.storeIDs;
  }

  @Selector()
  static getLoaded(state: CatalogStateModel) {
    return state.loaded;
  }

  @Selector()
  static getCatalogLoaded(state: CatalogStateModel) {
    return state.catalogsLoaded;
  }

  @Action(AddCatalog)
  addCatalog({getState, setState, patchState}: StateContext<CatalogStateModel>, {payload}: AddCatalog) {
    return this.facebookService.createCatalog(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const addedCatalog = response.body.catalogData;
          if (!addedCatalog) {
            return;
          }

          patchState({
            catalogData: getState().catalogData.concat(addedCatalog)
          });
          return this.analytics.trackEvent('add_catalog');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('add_catalog_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to create ${payload.name}`, error.message);
        this.logger.error('Catalog Error', error);
      });
  }

  @Action(DeleteCatalog)
  deleteCatalog({getState, setState, patchState}: StateContext<CatalogStateModel>, {payload}: DeleteCatalog) {
    return this.facebookService.deleteCatalog(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const deletedCatalogID = payload.id;
          if (!deletedCatalogID) {
            return;
          }
          let catalogData = state.catalogData.filter(catalog => catalog.id !== deletedCatalogID);

          patchState({
            catalogData
          });
          return this.analytics.trackEvent('delete_catalog');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('delete_catalog_error', {
          id: payload.id,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to delete catalog`, error.message);
        this.logger.error('Delete Catalog Error', error);
      });
  }

  @Action(EditCatalog)
  editCatalog({getState, setState, patchState}: StateContext<CatalogStateModel>, {payload}: AddCatalog) {
    return this.facebookService.editCatalog(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const editedCatalog = response.body.catalogData;
          if (!editedCatalog) {
            return;
          }
          let catalogData = state.catalogData;
          catalogData = catalogData.filter(catalog => catalog.id !== editedCatalog.id).concat(editedCatalog);

          patchState({
            catalogData
          });
          return this.analytics.trackEvent('edit_catalog');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_catalog_error', {
          name: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to edit ${payload.name}`, error.message);
        this.logger.error('Edit Catalog Error', error);
      });
  }

  @Action(GetCatalogs)
  getCatalogData({getState, patchState}: StateContext<CatalogStateModel>) {
    return this.facebookService.getCatalogs({})
      .then((response: ServerResponse) => {
        // this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const catalogData = response.body.catalogs;
          if (!catalogData) {
            patchState({
              loaded: true,
              catalogsLoaded: true,
            });
            return;
          }
          patchState({
            loaded: true,
            catalogsLoaded: true,
            catalogData,
            catalogIDs: Object.keys(catalogData)
          });
          return this.analytics.trackEvent('get_catalogs');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('get_catalogs_error', {
          message: error.message,
          state: getState()
        });
        this.toastr.error(error.message);
        this.logger.error('Get Catalog Error', error.message);
        if (error.status == 422) {
          this.store.dispatch(new DeleteFbToken());
        }
        patchState({
          loaded: true,
          catalogsLoaded: true,
        });
      });
  }

  @Action(GetACatalog)
  getCatalogByID({getState, patchState}: StateContext<CatalogStateModel>, {payload}: GetACatalog) {
    return this.facebookService.getCatalogByID(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          if (!storeID) {
            patchState({
              loaded: true,
              catalogsLoaded: true,
            });
            return;
          }
          patchState({
            loaded: true,
            catalogsLoaded: true,
          });
          return this.analytics.trackEvent('get_a_catalog');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('get_a_catalog_error', {
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to get store catalog`, error.message);
        this.logger.error('Get A Catalog Error', error);
        patchState({
          loaded: true,
          catalogsLoaded: true,
        });
      });
  }
}
