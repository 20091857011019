import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {ServerResponse, CuratorUser, UserProfile} from '@shared/model';
import {map, tap} from 'rxjs/operators';
import {ToastService} from '@app/service/util/toast.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {ProfileService} from '@shared/service/profile.service';
import {AddProfile, DeleteProfile, EditProfile, GetAProfile, GetProfiles} from '@shared/state/profile/profile.actions';
import {OnDestroy} from "@angular/core";
import {Subject} from "rxjs";

export interface ProfileStateModel {
  userProfiles: UserProfile[];
  userIDs: string[];
}

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    userProfiles: [],
    userIDs: []
  }
})
export class ProfileState implements OnDestroy {

  constructor(private profileService: ProfileService, private toastr: ToastService, private store: Store,
              private routingService: RoutingService, private logger: LoggerService, private analytics: AnalyticsService) {
  }

  @Selector()
  static getUserProfiles(state: ProfileStateModel) {
    return state.userProfiles;
  }

  @Selector()
  static getUserIDs(state: ProfileStateModel) {
    return state.userIDs;
  }

  @Action(GetProfiles)
  getProfiles({getState, patchState}: StateContext<ProfileStateModel>) {
    return this.profileService.getProfilesData().pipe(tap((result) => {
      if (!result) {
        return;
      }
      const userProfiles = result;
      const userIDs = userProfiles.map(user => user.uid);

      patchState({
        userProfiles,
        userIDs
      });
    }));
  }

  @Action(AddProfile)
  addProfile({getState, setState, patchState}: StateContext<ProfileStateModel>, {payload}: AddProfile) {
    return this.profileService.createProfile(payload)
      .then(async (response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const profileData = response.body.profileData;
          if (!profileData) {
            return;
          }
          await this.profileService.sendPasswordResetEmail(profileData.email);
          const userProfiles = state.userProfiles.filter(profile => profile.uid !== profileData.uid).concat(profileData);

          patchState({
            userProfiles,
            userIDs: userProfiles.map(profile => profile.uid)
          });
        }
        return this.analytics.trackEvent('add_profile');
      })
      .catch(error => {
        this.analytics.trackEvent('add_profile_error', {
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to create ${payload.name}`, error.message);
        this.logger.error('Profile Error', error);
      });
  }

  @Action(DeleteProfile)
  deleteProfile({getState, setState, patchState}: StateContext<ProfileStateModel>, {payload}: DeleteProfile) {
    return this.profileService.deleteProfile(payload.profileID)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const profileID = response.body.profileID;
          if (!profileID) {
            return;
          }
          const userIDs = state.userIDs;

          const updatedUserIDs = userIDs.filter(userID => userID !== profileID);
          const userProfiles = state.userProfiles.filter(profile => profile.uid !== profileID);

          patchState({
            userIDs: updatedUserIDs,
            userProfiles
          });
          return this.analytics.trackEvent('delete_profile');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('delete_profile_error', {
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to delete user profile`, error.message);
        this.logger.error('Profile Error', error);
      });
  }

  @Action(EditProfile)
  editProfile({getState, setState, patchState}: StateContext<ProfileStateModel>, {payload}: EditProfile) {
    return this.profileService.editProfile(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const profileData = response.body.profileData;
          if (!profileData) {
            return;
          }
          const userProfiles = state.userProfiles.filter(profile => profile.uid !== profileData.uid).concat(profileData);

          patchState({
            userProfiles,
            userIDs: userProfiles.map(profile => profile.uid)
          });
          return this.analytics.trackEvent('edit_profile');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_profile_error', {
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to edit ${payload.name}`, error.message);
        this.logger.error('Edit Profile Error', error);
      });
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
