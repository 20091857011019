import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {map, take, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '@shared/state/auth/auth.state';
import {AngularFireAuth} from '@angular/fire/auth';
import * as moment from 'moment';
import {CuratorUser} from '@shared/model';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {of, throwError} from 'rxjs';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {GetOrg, LoginSuccess} from '@shared/state/auth/auth.actions';
import {ToastService} from '@app/service/util/toast.service';
import {isOwner} from '@shared/helpers/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  headers: HttpHeaders;
  apiUrl: string;
  userID: string;
  userInfoPath: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store, private analyticsService: AnalyticsService,
              private firebaseAuth: AngularFireAuth, private toast: ToastService) {
    this.apiUrl = environment.apiUrl;
    this.userInfoPath = `users`;
  }

  getUserData(userID) {
    if (!userID) {
      return throwError({message: 'You are not authenticated, please refresh the page!'});
    }
    // return this.databaseService.database.ref(`${this.userInfoPath}/${userID}`).once('value');

    return this.firestore.collectionGroup<CuratorUser>('users', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`userID`, '==', userID);
      return query;
    }).snapshotChanges();
  }

  getOrgData(orgID) {
    return this.firestore.collection('orgs').doc(orgID)
      .snapshotChanges();
    // .pipe(take(1));
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  createOrgAndUser(data) {
    return this.http.post(this.getUrl('signup/create-user-org'), data)
      .toPromise();
  }

  linkWithFacebook() {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const userData = this.store.selectSnapshot(AuthState.getUserData);
    const provider = new firebase.auth.FacebookAuthProvider();
    const regularScope = 'catalog_management, business_management';
    const elevatedScope = ', attribution_read, read_insights, ads_management, ads_read';
    provider.addScope(regularScope);
    /**
     * Popup goes to external page, redirect is on the same page
     */

    return this.firebaseAuth.auth.currentUser.linkWithPopup(provider)
      .then(async (result: any) => {
        await this.writeFbResult(orgID, result);
        return result;
      })
      .catch(async error => {
        let result;
        if (error.code === 'auth/provider-already-linked') {
          result = await this.firebaseAuth.auth.currentUser.reauthenticateWithPopup(provider);
          await this.writeFbResult(orgID, result);
        }
        await this.analyticsService.trackEvent('facebook_linking_error', {
          error
        });
        return result;
      })
      .catch(async error => {
        const result = await this.firebaseAuth.auth.currentUser.reauthenticateWithRedirect(provider);
        await this.writeFbResult(orgID, result);
        await this.analyticsService.trackEvent('facebook_linking_error_2', {
          error
        });
        return result;
      })
      // .then(result => {
      //   if (result && result.additionalUserInfo && isOwner(userData.role)) {
      //     const fbUserID = result.additionalUserInfo.profile.id;
      //     const fbAccessToken = result.credential.accessToken;
      //     return this.getFbInfo({fbUserID, fbAccessToken});
      //   }
      // });
  }

  async writeFbResult(orgID, result) {
    const userData = this.store.selectSnapshot(AuthState.getUserData);
    if (!isOwner(userData.role)) {
      this.toast.error('Only owners of this team are allowed to provide Facebook access!',
        'Please reach out to the owner of your team to onboard Facebook', 10000);
      return this.firebaseAuth.auth.currentUser.updateProfile({
        photoURL: result.additionalUserInfo.profile.picture.data.url
      });
    }
    // Only the owners of an organization are allowed to link the FB Access token
    await this.firestore.collection('orgs').doc(orgID).update({
      fbAccessToken: result.credential.accessToken,
      fbUserID: result.additionalUserInfo.profile.id,
      updatedAt: moment().format('YYYYMMDDHHmmss')
    });
    this.toast.info('Please refresh the page to get the updated results!');
    return this.firebaseAuth.auth.currentUser.updateProfile({
      photoURL: result.additionalUserInfo.profile.picture.data.url
    });
    // return location.reload();
  }

  async writeFbBusinessResult(data) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    // Only the owners of an organization are allowed to link the FB Access token
    return this.firestore.collection('orgs').doc(orgID).update({
      fbBusinessID: data.fbBusinessID,
      updatedAt: moment().format('YYYYMMDDHHmmss')
    });
  }

  unlinkFacebook() {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.firebaseAuth.auth.currentUser.unlink('facebook.com')
      .then(async result => {
        await this.firestore.collection('orgs').doc(orgID).update({
          fbAccessToken: null,
          fbUserID: null,
          fbBusinessID: null
        });
        this.store.dispatch(new LoginSuccess(result));
      });
  }

  updateLoginTime(userID) {
    if (!userID) {
      userID = this.store.selectSnapshot(AuthState.getUserID);
    }
    return this.firestore.collectionGroup<CuratorUser>('users', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`userID`, '==', userID);
      return query;
    }).snapshotChanges().pipe(take(1), map(res => res[0].payload.doc.ref), tap(userRef => {
      userRef.update({
        lastLogin: parseInt(moment().format('YYYYMMDDHHmmss'), 10)
      });
    })).toPromise();
  }

  async editUserData(userData) {
    if (!userData) {
      return Promise.reject({message: 'No data exists!'});
    }
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const oldUserData = this.store.selectSnapshot(AuthState.getUserData);
    const name = userData.name;
    const email = userData.email;
    if (!name || !email) {
      return Promise.reject({message: 'No data exists!'});
    }
    if (oldUserData.email !== email) {
      await this.firebaseAuth.auth.currentUser.updateEmail(email);
    }
    if (oldUserData.name !== name) {
      await this.firebaseAuth.auth.currentUser.updateProfile({
        displayName: name
      });
    }
    await this.firestore.collection(`orgs/${orgID}/users`).doc(userID).update({
      name,
      email
    });
    return this.databaseService.database.ref(`${this.userInfoPath}/${userID}`)
      .update({
        name, email
      });
  }

  editOrg(data) {
    return this.http.post(this.getUrl('settings/edit-org'), data)
      .toPromise();
  }

  getFbInfo(data) {
    return this.http.post(this.getUrl('facebook/general/get-info'), data)
      .toPromise();
  }

  deleteFbToken() {
    return this.http.post(this.getUrl('auth/delete-fb-token'), {})
      .toPromise();
  }
}
