import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {ContentRoutingModule} from '@modules/content.routing';
import {NgxsModule} from '@ngxs/store';
import {StoreState} from '@shared/state/store/store.state';
import {AlertState} from '@shared/state/alert/alert.state';
import {FeedState} from '@shared/state/feed/feed.state';
import {BillingState} from '@shared/state/billing/billing.state';
import {InventoryState} from '@shared/state/inventory/inventory.state';
import {ProfileState} from '@shared/state/profile/profile.state';
import {FacebookCatalogState} from '@shared/state/facebook/catalog/catalog.state';
import {VdpAnalysisState} from '@shared/state/vdp-analysis/vdp-analysis.state';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    ContentRoutingModule,
    NgxsModule.forFeature([StoreState, AlertState, FeedState, BillingState, InventoryState,
      ProfileState, FacebookCatalogState, VdpAnalysisState])
  ]
})
export class ContentModule {
}
