import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {GetOrg, Logout} from '@shared/state/auth/auth.actions';
import {AuthState} from '@shared/state/auth/auth.state';
import {CuratorUser} from '@shared/model';
import {NavigationEnd, Router} from '@angular/router';
import {GetActiveServices, GetInactiveServices, GetPendingServices, GetStores} from '@shared/state/store/store.actions';
import {GetInvoices, GetPaymentMethods} from '@shared/state/billing/billing.actions';
import {ToastService} from '@app/service/util/toast.service';
import {GetProfiles} from '@shared/state/profile/profile.actions';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {RoutingService} from '@app/service/routing.service';

@Component({
  selector: 'dlr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  private ngUnSubscribe: Subject<void> = new Subject<void>();
  user: any;

  userMenu = [{
    title: 'Profile'
  }, {
    title: 'Logout'
  }];
  userPictureOnly = false;
  mediaObservable: Subscription;

  isRefreshing = false;
  showRefresh = true;

  @Select(AuthState.getUserData) user$: Observable<CuratorUser>;
  @Select(AuthState.getProviderData) providerData$: Observable<any[]>;

  constructor(private sidebarService: NbSidebarService, private store: Store, private toastr: ToastService,
              private menuService: NbMenuService, private cd: ChangeDetectorRef, private route: Router,
              private themeService: NbThemeService, private router: RoutingService, private analytics: AnalyticsService,
              private breakpointService: NbMediaBreakpointsService) {
    // Get the current route to determine if the refresh button should be shown or not for route.
    route.events
      .pipe(
        map(val => val instanceof NavigationEnd ? val : undefined),
        takeUntil(this.ngUnSubscribe)
      )
      .subscribe(val => {
        if (!val) {
          return;
        }
        const url: string = val.url;
        this.showRefresh = !url.includes('view-feed');
        this.cd.markForCheck();
      });
  }

  ngOnInit() {
    // Set the user data based on the userInfo observable
    this.user$
      .pipe(takeUntil(this.ngUnSubscribe))
      .subscribe(userData => {
      this.user = userData;
    });
    const {xl} = this.breakpointService.getBreakpointsMap();
    // Check to see if the width of the screen changed. If it did and it is less than XL dimentsions, show only image
    this.mediaObservable = this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.ngUnSubscribe),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // Handle when a menu option is clicked
    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'header-menu'),
        map(({item: {title}}) => title),
        takeUntil(this.ngUnSubscribe)
      )
      .subscribe(title => {
        if (title === 'Logout') {
          this.logout();
        } else if (title === 'Profile') {
          this.router.navigateToPath('settings/my-account');
        }
      });
  }

  ngOnDestroy() {
    this.mediaObservable.unsubscribe();
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
  }

  /**
   * Toggle the sidebar incase a user wants to view a route or change theme
   */
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.cd.markForCheck();

    return false;
  }

  /**
   * Handle when a user wants to refresh the page. When the refresh button is selected, trigger the store actions for
   * that specific page
   */
  refreshPage() {
    const url = this.route.url;
    let updateName = '';
    if (url.includes('billing')) {
      this.store.dispatch(new GetInactiveServices());
      this.store.dispatch(new GetPendingServices());
      this.store.dispatch(new GetActiveServices());
      this.store.dispatch(new GetInvoices());
      this.store.dispatch(new GetPaymentMethods());
      updateName = 'the Billing';
    } else if (url.includes('inventory-feeds')) {
      this.store.dispatch(new GetStores());
      updateName = 'the Inventory Feeds';
    } else if (url.includes('dashboard')) {
      this.store.dispatch(new GetInactiveServices());
      this.store.dispatch(new GetPendingServices());
      this.store.dispatch(new GetActiveServices());
      updateName = 'the Dashboard';
    } else if (url.includes('settings')) {
      updateName = 'the Settings';
      if (url.includes('my-account')) {
        updateName = 'my Profile';
      }
      this.store.dispatch(new GetOrg());
      this.store.dispatch(new GetProfiles());
    }
    this.toastr.success(`Successfully refreshed ${updateName} page`);
    this.cd.markForCheck();
    return this.analytics.trackEvent('refresh_page', {
      url
    });
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
