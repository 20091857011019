import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '@env';
import {LoggerService} from '@app/service/logger/logger.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {catchError, mergeMap} from 'rxjs/operators';
import {Actions, Store} from '@ngxs/store';
import {RoutingService} from '@app/service/routing.service';
import {interceptorConstants} from '@shared/model';
import {ToastService} from '@app/service/util/toast.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private logger: LoggerService, private store: Store, private actions: Actions,
              private routingService: RoutingService, private toast: ToastService,
              private firebaseAuth: AngularFireAuth) {
  }

  /**
   * For every request, add a bearer token for server side authentication and handle errors that are returned by API
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.firebaseAuth.auth.currentUser) {
      return next.handle(request);
    }
    // Get Auth Token that is saved for a user and make sure the request is to Dealer Curator specific api
    // const token = this.store.selectSnapshot(AuthState.getUserToken);
    return fromPromise(this.firebaseAuth.auth.currentUser.getIdToken())
      .pipe(mergeMap((token: string) => {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (token && isApiUrl) {
          let authReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          });
          // Always provide the ORG ID to every request body so that the request can be authenticated server side
          if (authReq.body) {
            const orgID = this.store.selectSnapshot(AuthState.getOrgID);
            authReq = authReq.clone({
              body: {...authReq.body, orgID}
            });
          }

          // Set request count limit on 500 error
          let errorCount = 0;

          // Pass on the cloned request instead of the original request and catch any errors
          return next.handle(authReq).pipe(catchError((error, caught) => {
            if (error.status === interceptorConstants.UNAUTHORIZED) {
              // logout users, redirect to login page
              // this.store.dispatch(new Logout());
            }

            // this.logger.error('error------>', error);

            if (error.status === interceptorConstants.PRECONDITION_FAILED) {
              // Reloading the page should fix the issue when no resource found
              location.reload();
            }

            if (error.status === interceptorConstants.INTERNAL_ERROR || error.status === interceptorConstants.GATEWAY_TIMEOUT) {
              if (errorCount < interceptorConstants.MAXIMUM_RE_REQUEST_LIMIT) {
                errorCount++;
                return next.handle(authReq);
              } else {
                this.routingService.navigateHome();
              }
            }

            // return all others errors
            return throwError(error);

          })) as any;
        } else {
          return next.handle(request);
        }
      }));
  }
}
