import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MENU_ITEMS, THEMES} from './content-const';
import {filter, take, takeUntil} from 'rxjs/operators';
import {NbThemeService} from '@nebular/theme';
import {Observable, Subject, Subscription} from 'rxjs';
import {ThemeService} from '@app/service/util/theme.service';
import {GetActiveServices, GetInactiveServices, GetPendingServices, GetStores} from '@shared/state/store/store.actions';
import {Select, Store} from '@ngxs/store';
import {GetAlerts} from '@shared/state/alert/alert.actions';
import {AuthState} from '@shared/state/auth/auth.state';
import {GetPaymentMethods} from '@shared/state/billing/billing.actions';
import {Event, NavigationEnd, Router, RouterStateSnapshot} from '@angular/router';
import {RouterState} from '@ngxs/router-plugin';
import {isAdmin, isOwner, isReadOnly} from '@shared/helpers/auth';
import {MessagingService} from '@shared/service/messaging.service';
import {StoreState} from "@shared/state/store/store.state";
import {GetProfiles} from "@shared/state/profile/profile.actions";

@Component({
  selector: 'dlr-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Select(RouterState.state) routerState: Observable<RouterStateSnapshot>;

  menu = MENU_ITEMS;
  currentTheme = 'default';
  themeObservable: Subscription;
  orgID: string;
  isEditRoute: boolean;

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef, private msgService: MessagingService,
              private nbThemeService: NbThemeService, private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetActiveServices());
    this.store.dispatch(new GetInactiveServices());
    this.store.dispatch(new GetPendingServices());
    this.store.dispatch(new GetAlerts());
    this.store.dispatch(new GetProfiles());
    /**
     * Listen for the user data being updated. Once it is updated, reload the payment methods, stores and alerts.
     * This is important since if a user's permission gets updated, the necessary changes needs to occur on the UI
     */
    this.store.select(AuthState.getUserData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userData) => {
        if (userData) {
          this.store.dispatch(new GetPaymentMethods());
          this.store.dispatch(new GetStores());
          this.menu = MENU_ITEMS; // Reset the menu items to flush added navigation
          const currentMenu = this.menu.map(val => val.title);
          // If the user's role is owner, add the billing link
          if (isOwner(userData.role) && !currentMenu.includes('Billing')) {
            this.menu.splice(4, 0, {
              title: 'Billing',
              icon: 'file-invoice-dollar',
              link: '/billing/summary',
              children: [
                {
                  title: 'Summary',
                  icon: 'wallet',
                  link: '/billing/summary',
                },
                {
                  title: 'Invoices',
                  icon: 'file-invoice-dollar',
                  link: '/billing/invoices',
                }
              ],
            });
          }
          if (!isReadOnly(userData.role) && !currentMenu.includes('Settings')) {
            const settingsMenu = {
              title: 'Settings',
              icon: 'cogs',
              link: '/settings/my-account',
              children: [{
                title: 'My Account',
                icon: 'user',
                link: '/settings/my-account',
              }]
            };
            if (isAdmin(userData.role)) {
              settingsMenu['children'].push({
                title: 'All User Accounts',
                icon: 'users',
                link: '/settings/other-users',
              })
            }
            if (isOwner(userData.role)) {
              settingsMenu['children'].push({
                title: 'Team Details',
                icon: 'building',
                link: '/settings/team-details',
              })
            }
            this.menu.splice(5, 0, settingsMenu);
          }
        }
      });

    this.store.select(StoreState.getActiveServices)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(services => {
        if (!services || services.length == 0) {
          return;
        }
        const currentMenu = this.menu.map(val => val.title);
        const vdpTitle = 'VDP Analysis';
        const facebookTitle = 'Facebook Hub';
        let foundVDP = false;
        let foundFBAds = false;
        for (const service of services) {
          if (!foundVDP && service.type === 'vdpAnalysis') {
            foundVDP = true;
          } else if (!foundFBAds && service.type === 'facebookAds') {
            foundFBAds = true;
          }
        }
        if (foundFBAds && !currentMenu.includes(facebookTitle)) {
          this.menu.splice(2, 0, {
            title: facebookTitle,
            icon: {
              icon: 'facebook-square',
              pack: 'brands'
            },
            link: '/facebook/catalogs',
            children: [
              {
                title: 'Catalog',
                icon: 'object-group',
                link: '/facebook/catalogs',
              }
            ],
          });
        } else if (!foundFBAds) {
          this.menu = this.menu.filter(menuItem => menuItem.title !== facebookTitle);
        }
        if (foundVDP && !currentMenu.includes(vdpTitle)) {
          this.menu.splice(this.menu.length - 3, 0, {
            title: vdpTitle,
            icon: 'chart-line',
            link: '/vdp-analysis/discover',
            children: [
              {
                title: 'Discover',
                icon: 'search-dollar',
                link: '/vdp-analysis/discover',
              },
              {
                title: 'Reports',
                icon: 'file-medical-alt',
                link: '/vdp-analysis/reports',
              }
            ],
          });
        } else if (!foundVDP) {
          this.menu = this.menu.filter(menuItem => menuItem.title !== vdpTitle);
        }
        this.cd.markForCheck();
      })
    // Listen for any new FCM messages
    this.msgService.listen();
    this.themeObservable = this.themeService.getCurrentTheme()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(currentTheme => {
        if (currentTheme) {
          this.currentTheme = currentTheme;
          this.nbThemeService.changeTheme(currentTheme);
        } else {
          this.currentTheme = 'dark';
        }
        this.cd.markForCheck();
      });
    // If it is an edit route, change the columns layout to be narrower
    this.routerState
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        this.isEditRoute = !!state.url.includes('/edit');
      });
  }

  changeTheme(themeName: string) {
    this.nbThemeService.changeTheme(themeName);
    this.themeService.setCurrentTheme(themeName);
  }

  ngOnDestroy() {
    this.themeObservable.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
