import {FeedAlarm, StoreData, StoreFeed} from '@shared/model';

export enum ActionTypes {
  GET_FEEDS = '[Feed] GetAllFeeds',
  GET_FEED = '[Feed] GetAFeed',
  GET_STORE_FEEDS = '[Feed] GetFeedsByStore',
  GET_FEED_ALARMS = '[Feed] GetAlarmsByStore',
  ADD_FEED = '[Feed] AddFeed',
  EDIT_FEED = '[Feed] EditFeed',
  DELETE_FEED = '[Feed] DeleteFeed',
  ADD_FEED_ALARM = '[Feed] AddFeedAlarm',
  EDIT_FEED_ALARM = '[Feed] EditFeedAlarm',
  DELETE_FEED_ALARM = '[Feed] DeleteFeedAlarm'
}

export class AddFeed {
  static readonly type = ActionTypes.ADD_FEED;
  constructor(public payload: Partial<StoreFeed> | any) {}
}

export class EditFeed {
  static readonly type = ActionTypes.EDIT_FEED;
  constructor(public payload: Partial<StoreFeed> | any) {}
}

export class DeleteFeed {
  static readonly type = ActionTypes.DELETE_FEED;
  constructor(public payload: any) {}
}

export class AddFeedAlarm {
  static readonly type = ActionTypes.ADD_FEED_ALARM;
  constructor(public payload: Partial<FeedAlarm> | any) {}
}

export class EditFeedAlarm {
  static readonly type = ActionTypes.EDIT_FEED_ALARM;
  constructor(public payload: Partial<FeedAlarm> | any) {}
}

export class DeleteFeedAlarm {
  static readonly type = ActionTypes.DELETE_FEED_ALARM;
  constructor(public payload:  {feedID: string, storeID: string, alarmID: string}) {}
}

// Get feeds using stores that a user has access to
export class GetFeeds {
  static readonly type = ActionTypes.GET_FEEDS;
  constructor() {}
}

export class GetStoreFeeds {
  static readonly type = ActionTypes.GET_STORE_FEEDS;
  constructor(public payload: {storeID: string}) {}
}

export class GetFeedAlarms {
  static readonly type = ActionTypes.GET_FEED_ALARMS;
  constructor(public payload: {storeID: string}) {}
}

export class GetAFeed {
  static readonly type = ActionTypes.GET_FEED;
  constructor(public payload: {feedID: string, storeID: string}) {}
}
