<router-outlet *ngIf="initialized$ | async; else loading"></router-outlet>

<ng-template #loading>
  <dlr-loading></dlr-loading>
</ng-template>

<div class="fb-customerchat" attribution=setup_tool page_id="114225453707747" minimized="true"></div>

<ng-template #logoutDialog let-ref="dialogRef">
  <nb-card>
    <nb-card-body>You will be logged out in 2 minutes due to inactivity. Dismiss this message to continue using Curator</nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="restartIdle(ref)" class="mr-3">Dismiss</button>
      <button nbButton status="danger" (click)="logout()">Logout</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
