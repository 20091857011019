import {State, Action, Selector, StateContext, NgxsOnInit} from '@ngxs/store';
import {
  CheckSession, CreateUser, DeleteFbToken, EditOrg, EditUser, GetFbBusinessInfo, GetOrg,
  GetUser, LinkFacebook,
  Login,
  LoginFailure,
  LoginRedirect,
  LoginSuccess,
  Logout,
  LogoutSuccess, RefreshToken, Register, ResetPassword, WriteFbBusinessInfo
} from '@shared/state/auth/auth.actions';
import {Org, ServerResponse, CuratorUser} from '@shared/model';
import {AngularFireAuth} from '@angular/fire/auth';
import {LoggerService} from '@app/service/logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {RoutingService} from '@app/service/routing.service';
import {UserService} from '@shared/service/user.service';
import {ToastService} from '@app/service/util/toast.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {authError} from '@shared/model/auth';
import {MessagingService} from "@shared/service/messaging.service";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {isOwner} from "@shared/helpers/auth";

export interface AuthStateModel {
  userData: Partial<CuratorUser>;
  facebookData: any;
  orgID: string;
  tokenID: string;
  userID: string;
  initialized: boolean;
  error: any;
  redirectUrl: string;
  fbToken: string;
  orgData: Partial<Org>;
  providerData: any[];
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userData: null,
    facebookData: {},
    orgID: null,
    tokenID: null,
    userID: null,
    initialized: false,
    error: null,
    redirectUrl: null,
    orgData: {},
    fbToken: null,
    providerData: null
  }
})
export class AuthState implements NgxsOnInit {
  ngUnsubscribe = new Subject();

  constructor(private firebaseAuth: AngularFireAuth, private logger: LoggerService, private http: HttpClient,
              private firebaseDB: AngularFireDatabase, private routingService: RoutingService,
              private userService: UserService, private toastService: ToastService, private analytics: AnalyticsService,
              private msgService: MessagingService) {
  }

  @Selector()
  static getUserData(state: AuthStateModel) {
    return state.userData;
  }

  @Selector()
  static getError(state: AuthStateModel) {
    return state.error;
  }

  @Selector()
  static getUserID(state: AuthStateModel) {
    return state.userID;
  }

  @Selector()
  static getUserToken(state: AuthStateModel) {
    return state.tokenID;
  }

  @Selector()
  static getOrgID(state: AuthStateModel) {
    return state.orgID;
  }

  @Selector()
  static getOrgData(state: AuthStateModel) {
    return state.orgData;
  }

  @Selector()
  static getInitialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static getFbToken(state: AuthStateModel): string {
    return state.fbToken;
  }

  @Selector()
  static getProviderData(state: AuthStateModel): any[] {
    return state.providerData;
  }

  @Selector()
  static getFacebookData(state: AuthStateModel): any {
    return state.facebookData;
  }

  /**
   * Dispatch CheckSession on start
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new CheckSession());
  }

  @Action(CheckSession)
  checkSession(sc: StateContext<AuthStateModel>) {
    return this.firebaseAuth.authState
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (user) => {
        if (user) {
          sc.dispatch(new LoginSuccess(user));
        } else {
          sc.dispatch(new LoginFailure({message: 'silent'}));
        }
      });
  }

  @Action(GetUser)
  getUserFromDB(sc: StateContext<AuthStateModel>): any {
    const userID = sc.getState().userID;
    return this.userService.getUserData(userID)
      .pipe(
        // take(1),
        map(res => res[0].payload.doc.data()),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((result: any) => {
        if (!result) {
          sc.patchState({
            initialized: true
          });
          return;
        }
        const userData = result;
        sc.patchState({
          userData,
          orgID: userData.orgID,
          initialized: true
        });
        this.goToPath(sc);
        sc.dispatch(new GetOrg());
        this.analytics.setUserProperties({
          name: userData.name,
          role: userData.role,
          orgID: userData.orgID
        });
      }, error => {
        this.toastService.error(error.message);
        return sc.patchState({
          initialized: true
        });
      });
  }

  /**
   * Redirect the user to the page they requested after the User data is added
   * @param sc
   */
  goToPath(sc: StateContext<AuthStateModel>) {
    let redirectUrl = sc.getState().redirectUrl;
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;
    // tslint:disable-next-line:no-conditional-assignment
    while (match = regex.exec(redirectUrl)) {
      params[match[1]] = match[2];
    }
    if (redirectUrl) {
      redirectUrl = redirectUrl.split('?')[0];
      sc.patchState({
        redirectUrl: null
      });
      return this.routingService.navigateToPath(redirectUrl, params);
    }
    return this.routingService.navigateHome();
  }

  @Action(GetOrg)
  getOrgData(sc: StateContext<AuthStateModel>) {
    const orgID = sc.getState().orgID;
    if (orgID) {
      this.userService.getOrgData(orgID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          const orgData: any = result.payload.data();
          const fbToken = orgData.fbAccessToken;
          sc.patchState({
            orgData,
            fbToken
          });
        });
    }
  }

  @Action(Login)
  signInWithEmail(sc: StateContext<AuthStateModel>, {payload}: Login) {
    return this.firebaseAuth.auth.signInWithEmailAndPassword(payload.email, payload.password)
      .then(async (user: firebase.auth.UserCredential) => {
        await this.userService.updateLoginTime(user.user.uid);
        return sc.dispatch(new LoginSuccess(user.user));
      })
      .catch(error => {
        return sc.dispatch(new LoginFailure(error));
      });
  }

  @Action(Logout)
  logout(sc: StateContext<AuthStateModel>) {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.firebaseAuth.auth.signOut().then(
      () => {
        this.msgService.deleteToken();
        return sc.dispatch(new LogoutSuccess());
      });
  }

  @Action(LoginSuccess)
  async setUserStateOnSuccess(sc: StateContext<AuthStateModel>, {payload}: LoginSuccess) {
    sc.patchState({
      tokenID: await payload.getIdToken(true),
      userID: payload.uid,
      providerData: payload.providerData
    });
    sc.dispatch(new GetUser());
  }

  @Action(LoginRedirect)
  redirectLogin(sc: StateContext<AuthStateModel>, {redirectUrl}: LoginRedirect) {
    if (redirectUrl) {
      sc.patchState({
        redirectUrl
      });
    }
    return this.routingService.navigateLogin();
  }

  @Action([LoginFailure, LogoutSuccess])
  setUserStateOnFailure(sc: StateContext<AuthStateModel>, {payload}: LoginFailure) {
    sc.patchState({
      userData: null,
      orgID: null,
      tokenID: null,
      userID: null,
      initialized: true,
      error: null,
      redirectUrl: null,
      orgData: null,
      fbToken: null,
      providerData: null
    });
    if (payload && payload.message) {
      const message = authError(payload.message);
      if (message && message !== 'Oops, error!') {
        this.toastService.error(message, '', 15000);
      }
      sc.patchState({
        error: {
          message,
          code: payload.code
        },
        initialized: true
      });
      return this.analytics.trackEvent('login_failure', {
        error: message
      });
    }
  }

  @Action(Register)
  createUser(sc: StateContext<AuthStateModel>, {payload}: CreateUser) {
    return this.userService.createOrgAndUser(payload)
      .then((response: ServerResponse) => {
        console.log(response);
        this.toastService.response(response.type, response.message);
        this.routingService.navigateLogin();
        return this.analytics.trackEvent('register', {
          name: payload.name,
          role: payload.uid,
          orgName: payload.orgName
        });
      })
      .catch((error) => {
        sc.dispatch(new LoginFailure({
          message: error
        }));
        // this.logger.error(error);
        // this.toastService.error(error.message);
      });
  }

  @Action(EditUser)
  editUser({getState, patchState, dispatch}: StateContext<AuthStateModel>, {payload}: EditUser) {
    return this.userService.editUserData(payload)
      .then(() => {
        const state = getState();
        const userData = state.userData;
        patchState({
          userData: {
            ...userData,
            name: payload.name,
            email: payload.email
          }
        });
      })
      .catch(error => {
        this.toastService.error(error.message);
        // this.logger.error(error.message);
      });
  }

  @Action(EditOrg)
  editOrg({getState, setState, patchState}: StateContext<AuthStateModel>, {payload}: EditOrg) {
    return this.userService.editOrg(payload)
      .then((response: ServerResponse) => {
        this.toastService.response(response.type, response.message);
        if (response.type === 'success') {
          let orgData: any = response.body.orgData;
          if (!orgData) {
            return;
          }
          const state = getState();
          const oldOrgData = state.orgData;

          orgData = Object.assign({}, oldOrgData, orgData);

          patchState({
            orgData
          });
          return this.analytics.trackEvent('edit_org');
        }
      })
      .catch(error => {
        this.analytics.trackEvent('edit_org_error', {
          message: error.message,
          state: getState()
        });
        this.toastService.error(`Unable to edit ${payload.name}`, error.message);
        // this.logger.error('Edit Organization Error', error);
      });
  }

  @Action(ResetPassword)
  resetPassword(sc: StateContext<AuthStateModel>, {payload}: any) {
    return this.firebaseAuth.auth.sendPasswordResetEmail(payload)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
        sc.dispatch(new LoginRedirect(''));
        return this.analytics.trackEvent('reset_password', {
          email: payload.email,
        });
      }).catch((error) => {
        // this.logger.error(error);
        this.toastService.error('There was an issue while trying to reset your account. Please contact support!');
        return this.analytics.trackEvent('reset_password_error', {
          email: payload.email,
          error
        });
      });
  }

  @Action(RefreshToken)
  refreshToken(sc: StateContext<AuthStateModel>) {
    return this.firebaseAuth.auth.currentUser.getIdToken(true)
      .then(async (tokenID) => {
        sc.patchState({
          tokenID
        });
        await this.analytics.trackEvent('refresh_token');
        return tokenID;
      }).catch((error) => {
        // this.logger.error(error);
      });
  }

  @Action(DeleteFbToken)
  deleteFbToken({getState, patchState}) {
    return this.userService.deleteFbToken()
      .then((response: ServerResponse) => {
        if (response.type === 'success') {
          const orgData: any = response.body.orgData;
          if (!orgData) {
            return;
          }
          patchState({
            orgData,
            fbToken: orgData.fbAccessToken
          });
          return this.analytics.trackEvent('delete_fb_token');
        }
      })
      .catch(error => {
        return this.analytics.trackEvent('delete_fb_token_error', {
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(LinkFacebook)
  linkFacebook(sc: StateContext<AuthStateModel>) {
    return this.userService.linkWithFacebook()
      .then(() => {
        return sc.dispatch(new GetOrg());
      })
      .then(() => {
        return sc.dispatch(new GetFbBusinessInfo());
      });
  }

  @Action(GetFbBusinessInfo)
  getFbBusinessInfo(sc: StateContext<AuthStateModel>) {
    const state = sc.getState();
    const orgData = state.orgData;
    if (!orgData || !orgData.fbUserID || !orgData.fbAccessToken) {
      return;
    }
    return this.userService.getFbInfo({fbUserID: orgData.fbUserID, fbAccessToken: orgData.fbAccessToken})
      .then((response: ServerResponse) => {
        const body = response.body;
        this.saveFacebookInfo(body, sc);
      });
  }

  @Action(WriteFbBusinessInfo)
  writeFbBusinessInfo(sc: StateContext<AuthStateModel>, {payload}: WriteFbBusinessInfo) {
    const state = sc.getState();
    if (!isOwner(state.userData.role)) {
      this.toastService.error('Only owners of this team are allowed to save facebook data!',
        'Please reach out to the owner of your team', 10000);
      return;
    }
    return this.userService.writeFbBusinessResult(payload)
      .then(() => {
        const fbBusinessID = payload.fbBusinessID;
        const orgData = state.orgData;
        sc.patchState({
          orgData: Object.assign({}, {...orgData}, {fbBusinessID})
        })
      });
  }

  saveFacebookInfo(results, sc: StateContext<AuthStateModel>) {
    const facebookData: any = {};
    if (!results || !results['updatedAt']) {
      return;
    }
    // facebookData['adAccounts'] = [{id: 'other', name: 'Other'}].concat(results.adAccounts);
    // facebookData['businessAccounts'] = [{id: 'other', name: 'Other'}].concat(results.businessAccounts);
    // facebookData['pixelIDs'] = [{id: 'other', name: 'Other'}].concat(results.pixelIDs);
    // facebookData['pageIDs'] = [{id: 'other', name: 'Other'}].concat(results.pageIDs);
    facebookData['adAccounts'] = results.adAccounts;
    facebookData['businessAccounts'] = [{id: 'other', name: 'Other'}].concat(results.businessAccounts);
    facebookData['pixelIDs'] = results.pixelIDs;
    facebookData['pageIDs'] = results.pageIDs;
    return sc.patchState({
      facebookData
    });
  }
}
