import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {mustMatch} from '@shared/validator/must-match';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {CuratorUser} from '@shared/model';
import {Store} from '@ngxs/store';
import {Register} from '@shared/state/auth/auth.actions';
import {ActivatedRoute} from '@angular/router';
import {Navigate} from '@ngxs/router-plugin';
import {ToastService} from '@app/service/util/toast.service';
import * as CryptoJS from 'crypto-js';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'dlr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', './../auth.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {
  submitted: boolean;
  messages: string[];
  registerForm: FormGroup;
  tokenID: string;

  constructor(private routeService: RoutingService, private ngZone: NgZone, private activeRoute: ActivatedRoute,
              private fb: FormBuilder, protected cd: ChangeDetectorRef, private logger: LoggerService,
              private store: Store, private toastr: ToastService) {
  }

  ngOnInit() {
    this.messages = [];
    this.submitted = false;

    this.registerForm = this.fb.group({
      orgName: ['', Validators.required],
      orgType: ['', Validators.required],
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: mustMatch('password', 'confirmPassword')
    });
    // In order to register, a user must have a signup token which also contains their payment plan
    this.tokenID = this.activeRoute.snapshot.queryParamMap.get('token');
    if (!this.tokenID) {
      this.toastr.error('Signup Token not found',
        'Registration for Dealer Curator is currently invite only, please reach out to support@dealercurator.com for more information!',
        20000);
      return this.routeService.navigateLogin();
    }
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get f() {
    return this.registerForm.controls;
  }

  register(): any {
    this.messages = [];
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const data = this.registerForm.value;
    const userData: Partial<CuratorUser> | any = {
      email: data.email,
      name: data.fullName,
      orgName: data.orgName,
      orgType: data.orgType,
      tokenID: this.tokenID
    };
    const key = 'dealer-curator-2020-swerving';
    const encPassword  = CryptoJS.AES.encrypt(data.password, key).toString();
    return this.store.dispatch(new Register({
      email: data.email,
      password: encPassword,
      ...userData
    }))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
      this.submitted = false;
      this.registerForm.reset();
    });
  }
}
