import { NbJSThemeOptions, DARK_THEME as baseTheme } from '@nebular/theme';

const baseThemeVariables = baseTheme.variables;

export const DARK_THEME = {
  name: 'dark',
  base: 'dark',
  variables: {

  },
} as NbJSThemeOptions;
