import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import {AuthState} from '@shared/state/auth/auth.state';
import {StoreFeed} from '@shared/model';
import {map, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getUrl(url) {
    return `${this.apiUrl}/facebook/${url}`;
  }

  deleteCatalog(data) {
    return this.http.post(this.getUrl('catalog/delete'), data)
      .toPromise();
  }

  createCatalog(data) {
    return this.http.post(this.getUrl('catalog/create'), data)
      .toPromise();
  }

  editCatalog(data) {
    return this.http.post(this.getUrl('catalog/edit'), data)
      .toPromise();
  }

  getCatalogs(data) {
    return this.http.post(this.getUrl('catalog/get-catalogs'), data)
      .toPromise();
  }

  getCatalogByID(data) {
    return this.http.post(this.getUrl('catalog/get-catalog-by-id'), data)
      .toPromise();
  }
}
