import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {ResetPassword} from '@shared/state/auth/auth.actions';
import {Store} from '@ngxs/store';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'dlr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', './../auth.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  errors: string[];
  messages: string[];
  user: any;
  submitted: boolean;
  loading = false;
  resetForm: FormGroup;

  constructor(private fb: FormBuilder, private routeService: RoutingService,
              private ngZone: NgZone, private logger: LoggerService, private store: Store) {
    this.messages = [];
    this.submitted = false;

    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get f() {
    return this.resetForm.controls;
  }


  forgotPassword(): Promise<any> {
    this.messages = [];
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;
    const data = this.resetForm.value;
    this.store.dispatch(new ResetPassword(data.email))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(success => {}, error => {
        this.logger.error(error);
        this.loading = false;
      });
  }
}
