<h1 class="main-title"><span class="thin">Dealer</span> Curator <span class="thin beta-text">beta</span></h1>
<p class="sub-title">Enter the email address associated with your account and we'll send you a link to reset your password.</p>

<nb-alert *ngIf="messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="forgotPassword()" [formGroup]="resetForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email</label>
    <input nbInput
           formControlName="email"
           id="input-email"
           placeholder="Email Address"
           fullWidth
           fieldSize="large"
           autofocus
           [status]="f.email.dirty ? (f.email.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.email.invalid && f.email.touched ? true : null">
    <ng-container *ngIf="f.email.invalid && f.email.touched">
      <p class="caption status-danger" *ngIf="f.email.errors?.required">
        Email is required!
      </p>
      <p class="caption status-danger" *ngIf="f.email.errors?.email">
        Email must be a valid email address!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !resetForm.valid"
          [class.btn-pulse]="submitted">
    Reset Password
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" [routerLink]="['/login']">Back to Log In</a></p>
<!--  <p>Don't have an account? <a [routerLink]="['/register']" class="text-link">Sign up</a></p>-->
</section>
