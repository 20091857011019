import {AlertType} from '@shared/model/alerts';
import {StoreFeed} from '@shared/model/feed';
import {StoreData} from '@shared/model/store';

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface Service {
  platform: string;
  status: string;
  createdAt: string | number;
  sourceUrl?: string;
  searchesLeft: number;
  facebookAdAccountID?: string;
  facebookPixelID?: string;
  facebookPageID?: string;
  adwordsConversionID?: string;
  adwordsCustomerID?: string;
  adwordsDynamicFeedID?: string;
  storeID: string;
  orgID: string;
  type: string;
}

export enum Role {
  superuser = 'superuser',
  owner = 'owner',
  admin = 'admin',
  user = 'user',
  readonly = 'readonly'
}

export interface ServerResponse {
  redirectUrl?: string;
  message: string;
  subtext: string;
  error?: string;
  data?: any;
  type: AlertType;
  body: any;
}

export interface StoreTreeNode {
  data: StoreData;
  children?: {data: StoreFeed}[];
  expanded?: boolean;
}

export interface GridTreeNode<T> {
  data: T;
  children?: GridTreeNode<T>[];
  expanded?: boolean;
}
