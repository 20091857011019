import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  /**
   * Navigate to established routes using the Store management router function
   * @param router
   * @param ngZone
   * @param store
   */
  constructor(private router: Router, private store: Store) {
  }

  navigateHome() {
    return this.store.dispatch(new Navigate(['/dashboard']));
  }

  navigateToStoreInventory() {
    return this.store.dispatch(new Navigate(['/inventory-feeds']));
  }

  navigateLogin() {
    return this.store.dispatch(new Navigate(['/login']));
  }

  navigateRegister() {
    return this.store.dispatch(new Navigate(['/register']));
  }

  navigateToAddStore() {
    return this.store.dispatch(new Navigate(['/add-store']));
  }

  navigateToAddProfile() {
    return this.store.dispatch(new Navigate(['/add-profile']));
  }

  navigateToBilling() {
    return this.store.dispatch(new Navigate(['/billing']));
  }

  navigateToPath(path, query?, extras?: NavigationExtras) {
    return this.store.dispatch(new Navigate([path], query, extras));
  }
}
