import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {RoutingService} from '@app/service/routing.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AuthStateGuard implements CanActivate {
  constructor(private store: Store, private routeService: RoutingService) {
  }

  /**
   * Auth state guard that routes a user to the dashboard if they are already authenticated
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.selectOnce(AuthState.getUserToken).pipe(
      map(isAuth => {
        if (!isAuth) {
          return true;
        }
        this.routeService.navigateHome();
        return true;
      })
    );
  }
}
