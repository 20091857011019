import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import {AuthState} from '@shared/state/auth/auth.state';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getAllInventoryData(storeID: string): Promise<DataSnapshot> {
    return this.databaseService.database.ref(`storeInventory/${storeID}/defaultFeed`)
      .once('value');
  }

  getEditedInventoryObject(storeID: string, invID: string) {
    return this.databaseService.database.ref(`storeInventory/${storeID}/defaultFeed/editedData`)
      .child(invID).once('value');
  }

  getDeletedInventoryObject(storeID: string, invID: string) {
    return this.databaseService.database.ref(`storeInventory/${storeID}/defaultFeed/deletedData`)
      .child(invID).once('value');
  }

  getInventoryObject(storeID: string, invID: string) {
    return this.databaseService.database.ref(`storeInventory/${storeID}/defaultFeed/data`)
      .child(invID).once('value');
  }

  getInventoryByFeed(storeID: string, feedID: string): Promise<any> {
    return this.databaseService.database.ref(`storeInventory/${storeID}/${feedID}`)
      .orderByKey()
      .once('value');
  }

  deleteInventoryItem(data: any) {
    return this.http.post(this.getUrl('inventory/delete-inventory-item'), data)
      .toPromise();
  }

  resetInventoryItem(data: any) {
    return this.http.post(this.getUrl('inventory/reset-inventory-item'), data)
      .toPromise();
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  editInventoryItem(data) {
    return this.http.post(this.getUrl('inventory/edit-inventory-item'), data)
      .toPromise();
  }
}
