import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {StoreData} from '@shared/model';
import {map, take, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '@shared/state/auth/auth.state';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {allowedPlatforms} from "@shared/helpers/helpers";

@Injectable({
  providedIn: 'root'
})
export class StoreService implements OnDestroy {
  headers: HttpHeaders;
  apiUrl: string;
  destroy$ = new Subject<void>();

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  /**
   * Subscribe to the stores a user has access to. If there is a change to the use r's store access, role or anything in this path,
   * immediately reflect that for the user
   */
  getStoresData() {
    const userID = this.store.selectSnapshot(AuthState.getUserID);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const storePath = `orgs/${orgID}/stores`;
    if (!userID || !orgID) {
      return of([]);
    }
    return this.firestore.collection<StoreData>(storePath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`users`, 'array-contains', userID);
      query = query.orderBy('name');
      return query;
    }).snapshotChanges().pipe(
      takeUntil(this.destroy$),
      // take(1), // If the reads start getting expensive
      map(documents => {
        const storeArray: StoreData[] = [];
        documents.forEach(doc => {
          const store = doc.payload.doc.data();
          const storeID = doc.payload.doc.id;
          storeArray.push({
            id: storeID,
            ...store
          });
        });
        return storeArray;
      })
    );
  }

  getServiceData(storeID, serviceID) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const storePath = `orgs/${orgID}/stores`;
    return this.firestore.collection<StoreData>(storePath).doc(storeID)
      .collection('services').doc(serviceID).get();
  }

  getPendingServices() {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    if (!orgID) {
      return of([]);
    }
    return this.firestore.collectionGroup<any>('services', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`isActive`, '==', false);
      query = query.where(`setupComplete`, '==', false);
      query = query.where(`platform`, 'in', allowedPlatforms);
      query = query.where(`orgID`, '==', orgID);
      return query;
    }).snapshotChanges().pipe(
      takeUntil(this.destroy$),
      // take(1), // If the reads start getting expensive
      map(documents => {
        const serviceArray: any[] = [];
        documents.forEach(doc => {
          const service = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          const serviceID = `${service.storeID}_${id}`
          serviceArray.push({
            id: serviceID,
            type: id,
            ...service
          });
        });
        return serviceArray;
      })
    );
  }

  getActiveServices() {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    if (!orgID) {
      return of([]);
    }
    return this.firestore.collectionGroup<any>('services', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`isActive`, '==', true);
      query = query.where(`setupComplete`, '==', true);
      query = query.where(`platform`, 'in', allowedPlatforms);
      query = query.where(`orgID`, '==', orgID);
      return query;
    }).snapshotChanges().pipe(
      takeUntil(this.destroy$),
      // take(1),
      map(documents => {
        const serviceArray: any[] = [];
        documents.forEach(doc => {
          const service = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          const serviceID = `${service.storeID}_${id}`
          serviceArray.push({
            id: serviceID,
            type: id,
            ...service
          });
        });
        return serviceArray;
      })
    );
  }

  getInactiveServices() {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    if (!orgID) {
      return of([]);
    }
    return this.firestore.collectionGroup<any>('services', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`isActive`, '==', false);
      query = query.where(`setupComplete`, '==', true);
      query = query.where(`platform`, 'in', allowedPlatforms);
      query = query.where(`orgID`, '==', orgID);
      return query;
    }).snapshotChanges().pipe(
      takeUntil(this.destroy$),
      // take(1),
      map(documents => {
        const serviceArray: any[] = [];
        documents.forEach(doc => {
          const service = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          const serviceID = `${service.storeID}_${id}`
          serviceArray.push({
            id: serviceID,
            type: id,
            ...service
          });
        });
        return serviceArray;
      })
    );
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  getStoresByUrl(data) {
    return this.http.post(this.getUrl('stores/get-stores'), data)
      .toPromise();
  }

  createStore(data) {
    return this.http.post(this.getUrl('stores/create-store'), data)
      .toPromise();
  }

  disableActiveService(data) {
    return this.http.post(this.getUrl('stores/disable-active-service'), data)
      .toPromise();
  }

  reEnableDisabledService(data) {
    return this.http.post(this.getUrl('stores/reenable-disabled-service'), data)
      .toPromise();
  }

  enableInactiveServices(data) {
    return this.http.post(this.getUrl('stores/enable-inactive-services'), data)
      .toPromise();
  }

  editStore(data) {
    return this.http.put(this.getUrl('stores/edit-store'), data)
      .toPromise();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
