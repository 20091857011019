import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import Stripe = stripe.Stripe;

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  getPaymentMethods(data) {
    return this.http.post(this.getUrl('billing/get-payment-methods'), data)
      .toPromise();
  }

  getStripeBilling(data) {
    return this.http.post(this.getUrl('billing/get-stripe-billing'), data)
      .toPromise();
  }

  getInvoices(data) {
    return this.http.post(this.getUrl('billing/get-invoices'), data)
      .toPromise();
  }

  payInvoice(data) {
    return this.http.post(this.getUrl('billing/pay-invoice'), data)
      .toPromise();
  }

  deleteInvoice(data) {
    return this.http.post(this.getUrl('billing/delete-invoice'), data)
      .toPromise();
  }

  deleteInvoiceItem(data) {
    return this.http.post(this.getUrl('billing/delete-invoice-item'), data)
      .toPromise();
  }

  setupIntent(data) {
    return this.http.post(this.getUrl('billing/setup-intent'), data)
      .toPromise();
  }

  paymentMethodSetup(stripe: Stripe, secret, data) {
    return stripe.confirmCardSetup(secret, {
      payment_method: data,
    });
  }

  addPaymentMethod(data) {
    return this.http.post(this.getUrl('billing/add-payment-method'), data)
      .toPromise();
  }

  setDefaultPaymentMethod(data) {
    return this.http.post(this.getUrl('billing/set-payment-method'), data)
      .toPromise();
  }

  finalizePendingSetups(data) {
    return this.http.post(this.getUrl('billing/finalize-pending-setups'), data)
      .toPromise();
  }

  deletePaymentMethod(data) {
    return this.http.post(this.getUrl('billing/delete-payment-method'), data)
      .toPromise();
  }

  handleError(error: any): void {
    if (!error.response || !error.response.data || !error.response.data) {
      throw {message: 'Unknown Error'};
    }
    throw error.response.data.error || error.response.data.message;
  }
}
