<nb-layout>
  <nb-layout-header fixed>
    <dlr-header></dlr-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
    <nb-menu [items]="menu" autoCollapse="true"></nb-menu>
    <nb-sidebar-footer>
<!--      <p class="label text-danger">Disable Ad Blocker</p>-->
    </nb-sidebar-footer>
  </nb-sidebar>

<!--  <nb-layout-column class="three-columns-layout" *ngIf="isEditRoute" left>-->
<!--  </nb-layout-column>-->

  <nb-layout-column [class.is-three-cols]="isEditRoute">
    <router-outlet></router-outlet>
  </nb-layout-column>

<!--  <nb-layout-column class="three-columns-layout" *ngIf="isEditRoute">-->
<!--  </nb-layout-column>-->

  <nb-layout-footer fixed>
    <dlr-footer></dlr-footer>
  </nb-layout-footer>
</nb-layout>
