import {NgModule} from '@angular/core';
import {CapitalizePipe} from '@shared/pipes/capitalize.pipe';
import {ShortenPipe} from '@shared/pipes/shorten.pipe';
import {IconifyPipe} from '@shared/pipes/iconify.pipe';
import {CalendarPipe} from '@shared/pipes/moment/calendar.pipe';
import {DateFormatterPipe} from '@shared/pipes/moment/date-formatter.pipe';
import {FromNowPipe} from '@shared/pipes/moment/from-now.pipe';
import {HasPermissionPipe} from '@shared/pipes/has-permission.pipe';
import {MoneyPipe} from '@shared/pipes/money.pipe';
import {TimeSincePipe} from '@shared/pipes/moment/time-since.pipe';
import {ZeroifyPipe} from '@shared/pipes/zeroify.pipe';
import {DeCamelcasePipe} from "@shared/pipes/de-camelcase.pipe";
import {VdpQueryFormatterPipe} from "@shared/pipes/vdp-query-formatter.pipe";
import {AlarmTextFormatterPipe} from "@shared/pipes/alarm-text-formatter.pipe";
import {IntervalFormatterPipe} from "@shared/pipes/interval-formatter.pipe";

@NgModule({
  exports: [
    ShortenPipe, IntervalFormatterPipe,
    CalendarPipe, AlarmTextFormatterPipe,
    IconifyPipe, VdpQueryFormatterPipe,
    HasPermissionPipe, ZeroifyPipe, CapitalizePipe, DeCamelcasePipe, FromNowPipe, MoneyPipe, DateFormatterPipe
  ],
  declarations: [
    CapitalizePipe, ShortenPipe, AlarmTextFormatterPipe,
    DateFormatterPipe, CalendarPipe, IntervalFormatterPipe,
    FromNowPipe, IconifyPipe, VdpQueryFormatterPipe,
    HasPermissionPipe, MoneyPipe, TimeSincePipe, ZeroifyPipe, DeCamelcasePipe
  ]
})

export class PipeModule {
}
