import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {ServerResponse, VehicleInventory} from '@shared/model';
import {ToastService} from '@app/service/util/toast.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {
  DeleteInventoryItem,
  EditInventoryItem,
  GetAllInventory, GetInventoryByID, GetInventoryIDsByFeed, GetInventoryItems, ResetInventoryItem
} from '@shared/state/inventory/inventory.actions';
import {InventoryService} from '@shared/service/inventory.service';
import {DataSnapshot} from '@angular/fire/database/interfaces';
import {removeByKey} from "@shared/helpers/helpers";
import {GetAFeed} from "@shared/state/feed/feed.actions";

/**
 * Inventory Data will be stored using the storeID as a primary key
 */
export interface InventoryStateModel {
  inventoryData: {
    [k: string]: {
      [k: string]: Partial<VehicleInventory>
    }
  };
  // In each of the feeds in an org, you will have objects with vins as the key and true as value
  feedInventoryIDs: {
    [k: string]: {
      [k: string]: true
    }
  };
  // Get the feedIDs array for each store
  feedIDs: {
    [k: string]: string[]
  };
}

@State<InventoryStateModel>({
  name: 'inventory',
  defaults: {
    inventoryData: {},
    feedInventoryIDs: {},
    feedIDs: {}
  }
})
export class InventoryState {
  constructor(private inventoryService: InventoryService, private toastr: ToastService, private store: Store,
              private routingService: RoutingService, private logger: LoggerService,
              private analytics: AnalyticsService) {
  }

  @Selector()
  static getInventoryData(state: InventoryStateModel) {
    return state.inventoryData;
  }

  @Selector()
  static getFeedInventoryIDs(state: InventoryStateModel) {
    return state.feedInventoryIDs;
  }

  @Selector()
  static getFeedIDs(state: InventoryStateModel) {
    return state.feedIDs;
  }


  @Action(GetAllInventory)
  getFeedData({getState, patchState}: StateContext<InventoryStateModel>, {payload}: GetAllInventory) {
    const storeID = payload.storeID;
    const state = getState();
    let inventoryData = state.inventoryData;
    if (!inventoryData[storeID]) {
      return this.inventoryService.getAllInventoryData(storeID)
        .then((result) => {
          const response = result.val();
          if (!response) {
            throw new Error(`No store inventory data for ${storeID}`);
          }
          inventoryData = Object.assign({}, inventoryData, {[storeID]: response.data});
          patchState({
            inventoryData
          });
          return this.analytics.trackEvent('get_all_inventory', {
            storeID
          });
        })
        .catch(err => {
          return this.analytics.trackEvent('get_all_inventory_error', {
            storeID,
            message: err.message,
            state: getState()
          });
        });
    }
  }

  @Action(GetInventoryIDsByFeed)
  getInventoryIDsByFeed({getState, patchState}: StateContext<InventoryStateModel>, {payload}: GetInventoryIDsByFeed) {
    const storeID = payload.storeID;
    const feedID = payload.feedID;
    return this.inventoryService.getInventoryByFeed(storeID, feedID)
      .then(async (result: DataSnapshot) => {
        const invData = result.val();
        if (!invData) {
          throw new Error(`No data for FEED ${feedID} in store ${storeID}`);
        }
        const state = getState();
        // Get the vins that are in the store, unique by storeID
        let feedInventoryIDs = state.feedInventoryIDs;
        let feedIDs = state.feedIDs; // Get the stores feedIDs
        let currentFeedIDs = feedIDs[storeID];

        const updatedInventoryIDs = invData.data; // Get the vins that were from a filtered feed

        // If no feedID array exist for that store, create one
        if (!currentFeedIDs) {
          currentFeedIDs = [feedID];
        } else {
          // If there is an array, ensure the feedID is added without duplication
          currentFeedIDs = currentFeedIDs
            .filter(stateFeedID => stateFeedID !== feedID)
            .concat(feedID);
        }

        // Workaround to update a readonly object
        feedIDs = Object.assign({}, feedIDs, {[storeID]: currentFeedIDs});
        // Get an updated version of the inventory feed IDs
        feedInventoryIDs = Object.assign({}, feedInventoryIDs, {[feedID]: updatedInventoryIDs});

        patchState({
          feedIDs,
          feedInventoryIDs
        });
        return this.analytics.trackEvent('get_inventory_ids_by_feed', {
          feedID,
          storeID
        });
      }).catch((err: Error) => {
        this.logger.error('INVENTORY BY FEEDS', err);
        return this.analytics.trackEvent('get_inventory_ids_by_feed_error', {
          feedID,
          message: err.message,
          state: getState()
        });
      });
  }

  @Action(GetInventoryItems)
  async getInventoryItemsByFeed({getState, patchState}: StateContext<InventoryStateModel>, {payload}: GetInventoryItems) {
    const storeID = payload.storeID;
    const feedID = payload.feedID;
    const inventoryKeys = payload.inventoryKeys;

    try {
      const state = getState();
      let inventoryData = state.inventoryData; // Get the inventory data for each store
      let currentInvData = inventoryData[storeID]; // Get the data currently in the store system

      // If no store inventory is in the NGXS Store, init a new instance
      if (!currentInvData) {
        currentInvData = await this.getInventoryData(storeID, inventoryKeys, {});
        // Append the data for the store into the Object containing all the inventory data for all client stores
        inventoryData = Object.assign({}, inventoryData, {[storeID]: currentInvData});
      } else {
        // Omit vehicles that are already in the store
        currentInvData = await this.getInventoryData(storeID, inventoryKeys, currentInvData);
        inventoryData = Object.assign({}, inventoryData, {[storeID]: currentInvData});
      }
      patchState({
        inventoryData
      });
      return this.analytics.trackEvent('get_inventory_by_feed', {
        feedID,
        storeID
      });
    } catch (err) {
      return this.analytics.trackEvent('get_inventory_by_feed_error', {
        feedID,
        message: err.message,
        state: getState()
      });
    }
  }

  @Action(GetInventoryByID)
  async getInventoryByVin({getState, patchState}: StateContext<InventoryStateModel>, {payload}: GetInventoryByID) {
    const storeID = payload.storeID;
    const vin = payload.vin;

    try {
      const state = getState();
      let inventoryData = state.inventoryData; // Get the inventory data for each store
      let currentInvData = inventoryData[storeID]; // Get the data currently in the store system

      // If no store inventory is in the NGXS Store, init a new instance
      if (!currentInvData) {
        currentInvData = await this.getInventoryData(storeID, [vin], {});
        // Append the data for the store into the Object containing the vehicle data for all stores
        inventoryData = Object.assign({}, inventoryData, {[storeID]: currentInvData});
      } else {
        currentInvData = await this.getInventoryData(storeID, [vin], currentInvData);
        inventoryData = Object.assign({}, inventoryData, {[storeID]: currentInvData});
      }
      patchState({
        inventoryData
      });
      return this.analytics.trackEvent('get_inventory_by_vin', {
        storeID
      });
    } catch (err) {
      return this.analytics.trackEvent('get_inventory_by_vin_error', {
        message: err.message,
        state: getState()
      });
    }
  }

  /**
   *
   * @param storeID
   * @param vinArr - VINs belonging to a feed, they may also be paginated
   * @param existingInventory - Data that exists for the org store
   */
  async getInventoryData(storeID: string, vinArr: string[], existingInventory = {}): Promise<any> {
    const existingVins = Object.keys(existingInventory);
    // If the vin is already a part of the store, skip these vehicles unless its a refresh
    const vins = vinArr.filter(vin => !existingVins.includes(vin));
    const output = Object.assign({}, existingInventory);

    for (const vin of vins) {
      const deletedData = (await this.inventoryService.getDeletedInventoryObject(storeID, vin));
      if (deletedData.exists()) {
        output[vin] = null;
        continue;
      }
      const editedData = (await this.inventoryService.getEditedInventoryObject(storeID, vin));
      if (editedData.exists()) {
        output[vin] = editedData.val();
        continue;
      }
      const data = (await this.inventoryService.getInventoryObject(storeID, vin)).val();
      if (data) {
        output[vin] = data;
      }
    }
    return output;
  }

  @Action(DeleteInventoryItem)
  deleteInventoryItem({getState, setState, patchState}: StateContext<InventoryStateModel>, {payload}: DeleteInventoryItem) {
    return this.inventoryService.deleteInventoryItem(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const feedID = response.body.feedID;
          const storeID = response.body.storeID;
          const deletedVin = response.body.vin;
          const invCount = response.body.invCount;
          if (!feedID || !deletedVin) {
            return;
          }
          let feedInventoryIDs = state.feedInventoryIDs;
          let feedInventory = feedInventoryIDs[feedID]; // The vehicles vin that belongs to a feed
          if (!feedInventory) {
            return;
          }

          feedInventory = removeByKey(feedInventory, deletedVin);
          feedInventoryIDs = Object.assign({}, feedInventoryIDs, {[feedID]: feedInventory});

          patchState({
            feedInventoryIDs
          });
          this.store.dispatch(new GetAFeed({feedID, storeID}));
          return this.analytics.trackEvent('delete_inventory_item');
        }
      })
      .catch(error => {
        this.toastr.error(`Unable to delete ${payload.vin}`, error.message);
        this.logger.error('Delete Inventory Item Error', error);
        return this.analytics.trackEvent('delete_inventory_item_error', {
          vin: payload.vin,
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(ResetInventoryItem)
  resetInventoryItem({getState, setState, patchState}: StateContext<InventoryStateModel>, {payload}: ResetInventoryItem) {
    return this.inventoryService.resetInventoryItem(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          const vin = response.body.vin;
          const invData = response.body.invData;
          if (!invData) {
            return;
          }
          const inventoryData = state.inventoryData;
          let storeInvData = inventoryData[storeID]; // Object with vin as unique key
          storeInvData = Object.assign({}, storeInvData, {[vin]: invData});

          patchState({
            inventoryData: Object.assign({}, inventoryData, {[storeID]: storeInvData})
          })
          return this.analytics.trackEvent('reset_inventory_item');
        }
      })
      .catch(error => {
        this.toastr.error(`Unable to reset ${payload.vin}`, error.message);
        this.logger.error('Reset Inventory Item Error', error);
        return this.analytics.trackEvent('reset_inventory_item_error', {
          vin: payload.vin,
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(EditInventoryItem)
  editInventoryItem({getState, setState, patchState}: StateContext<InventoryStateModel>, {payload}: EditInventoryItem) {
    return this.inventoryService.editInventoryItem(payload)
      .then((response: ServerResponse) => {
        this.toastr.response(response.type, response.message);
        if (response.type === 'success') {
          const state = getState();
          const storeID = response.body.storeID;
          const vin = response.body.vin;
          const invData = response.body.invData;
          if (!storeID) {
            return;
          }
          const inventoryData = state.inventoryData;
          let storeInvData = inventoryData[storeID]; // Object with vin as unique key
          storeInvData = Object.assign({}, storeInvData, {[vin]: invData});

          patchState({
            inventoryData: Object.assign({}, inventoryData, {[storeID]: storeInvData})
          })
          return this.analytics.trackEvent('edit_inventory_item');
        }
      })
      .catch(error => {
        this.toastr.error(`Unable to edit ${payload.data.title}`, error.message);
        this.logger.error('Edit inventory vehicle Error', error);
        return this.analytics.trackEvent('edit_inventory_item_error', {
          vin: payload.data.vin,
          message: error.message,
          state: getState()
        });
      });
  }
}
