import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home',
    link: '/dashboard',
    home: true,
  },
  {
    title: 'Inventory Feeds',
    icon: 'cart-plus',
    link: '/inventory-feeds/feeds',
    children: [
      {
        title: 'Store Feeds',
        icon: 'cart-plus',
        link: '/inventory-feeds/feeds',
      },
      {
        title: 'Feed Monitor',
        icon: 'bell',
        link: '/inventory-feeds/monitoring',
      }
    ],
  },
  {
    title: 'Alerts',
    icon: 'bell',
    link: '/alerts'
  },
  // {
  //   title: 'Help',
  //   icon: 'question-circle',
  //   link: '/help'
  // },
];

export const THEMES = [
  {
    value: 'default',
    name: 'Light',
  },
  {
    value: 'dark',
    name: 'Dark',
  },
  {
    value: 'cosmic',
    name: 'Retro',
  },
  {
    value: 'material-dark',
    name: 'Midnight',
  },
];
