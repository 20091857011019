import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {LoggerService} from '@app/service/logger/logger.service';
import {
  AddPaymentMethod, DeleteInvoice, DeleteInvoiceItem,
  DeletePaymentMethod, FinalizePendingSetups,
  GetInvoices,
  GetPaymentMethods, GetStripeBilling, PayInvoice,
  SetPaymentMethod
} from '@shared/state/billing/billing.actions';
import {BillingService} from '@shared/service/billing.service';
import {AuthState} from '@shared/state/auth/auth.state';
import {ToastService} from '@app/service/util/toast.service';
import {ServerResponse} from '@shared/model';
import {serverResponseToast} from '@shared/helpers/helpers';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {RoutingService} from "@app/service/routing.service";

export interface BillingStateModel {
  stripeCustomerInfo: any;
  defaultPaymentMethod: string;
  pendingInDraft: {};
  paymentSources: any[];
  invoicesInfo: any[];
  loaded: boolean;
}

@State<BillingStateModel>({
  name: 'billing',
  defaults: {
    stripeCustomerInfo: {},
    defaultPaymentMethod: '',
    paymentSources: [],
    pendingInDraft: {},
    loaded: false,
    invoicesInfo: [],
  }
})
export class BillingState {
  duration = 10000;

  constructor(private logger: LoggerService, private billing: BillingService, private store: Store,
              private toast: ToastService, private analytics: AnalyticsService, private routingService: RoutingService) {

  }

  @Selector()
  static getBillingInfo(state: BillingStateModel) {
    return state.stripeCustomerInfo;
  }

  @Selector()
  static getDefaultPaymentMethod(state: BillingStateModel) {
    return state.defaultPaymentMethod;
  }

  @Selector()
  static getPaymentSources(state: BillingStateModel) {
    return state.paymentSources;
  }

  @Selector()
  static getInvoicesInfo(state: BillingStateModel) {
    return state.invoicesInfo;
  }

  @Selector()
  static getPendingInDraft(state: BillingStateModel) {
    return state.pendingInDraft;
  }

  @Selector()
  static getBillingLoaded(state: BillingStateModel) {
    return state.loaded;
  }

  @Action(AddPaymentMethod)
  addPaymentMethod({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: AddPaymentMethod) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const customerID = payload.customerID;
    const paymentMethod = payload.paymentMethod;
    return this.billing.addPaymentMethod({orgID, customerID, paymentMethod})
      .then((res: any) => {
        const sources = getState().paymentSources.concat([res.data.paymentMethod]);
        let stripeCustomerInfo = getState().stripeCustomerInfo;
        let defaultPaymentMethod = '';
        if (res.data.stripeCustomerInfo) {
          stripeCustomerInfo = res.data.stripeCustomerInfo;
        }
        if (res.data.defaultPaymentMethod) {
          defaultPaymentMethod = res.data.defaultPaymentMethod;
        }
        patchState({
          paymentSources: sources,
          stripeCustomerInfo,
          defaultPaymentMethod
        });
        serverResponseToast(this.toast, res);
      }).catch(err => {
        this.toast.error(err.message);
      });
  }

  @Action(SetPaymentMethod)
  setPaymentMethod({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: SetPaymentMethod) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const orgData = this.store.selectSnapshot(AuthState.getOrgData);
    const customerID = payload.customerID;
    const paymentMethod = payload.paymentMethodID;

    return this.billing.setDefaultPaymentMethod({
      orgID, paymentMethod, customerID, orgName: orgData.name
    }).then((res: any) => {
      serverResponseToast(this.toast, res);
      patchState({
        stripeCustomerInfo: res.data.stripeCustomerInfo,
        defaultPaymentMethod: res.data.defaultPaymentMethod
      });
    }).catch(err => {
      this.toast.error(err.message);
    });
  }

  @Action(DeletePaymentMethod)
  deletePaymentMethod({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: DeletePaymentMethod) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const orgData = this.store.selectSnapshot(AuthState.getOrgData);
    const customerID = payload.customerID;
    const paymentMethod = payload.paymentMethodID;

    return this.billing.deletePaymentMethod({
      orgID, paymentMethod, customerID, orgName: orgData.name
    }).then((res: any) => {
      serverResponseToast(this.toast, res);
      const paymentSources = getState().paymentSources;
      const newPaymentSources = [];
      for (const source of paymentSources) {
        if (source.id !== paymentMethod) {
          newPaymentSources.push(source);
        }
      }
      if (res.data) {
        patchState({
          stripeCustomerInfo: res.data.stripeCustomerInfo,
          defaultPaymentMethod: res.data.defaultPaymentMethod
        });
      }
      patchState({
        paymentSources: newPaymentSources
      });
    }).catch(err => {
      this.toast.error(err.message);
    });
  }

  @Action(GetPaymentMethods)
  getPaymentMethods({getState, patchState}: StateContext<BillingStateModel>) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.billing.getPaymentMethods({orgID})
      .then((res: any) => {
        const stripeCustomerInfo = res.data.stripeCustomerInfo;
        patchState({
          stripeCustomerInfo,
          defaultPaymentMethod: res.data.defaultPaymentMethod,
          paymentSources: res.data.paymentMethods,
          loaded: true
        });
      })
      .catch(err => {
        this.logger.error(err);
        patchState({
          loaded: true
        });
        this.toast.error(err.message, 'Please refresh the page!');
      });
  }

  @Action(GetStripeBilling)
  getStripeBilling({getState, patchState}: StateContext<BillingStateModel>) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.billing.getStripeBilling({orgID})
      .then((res: any) => {
        const stripeSession = res.data.stripeSession;
        if (!stripeSession) {
          return;
        }
        window.open(stripeSession.url, '_self');
        return this.analytics.trackEvent('get_stripe_dashboard', {
          orgID
        });
      })
      .catch(err => {
        patchState({
          loaded: true
        });
        this.toast.error(err.message, 'Please refresh the page!');
      });
  }

  @Action(GetInvoices)
  getInvoices({getState, patchState}: StateContext<BillingStateModel>) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.billing.getInvoices({orgID})
      .then((res: ServerResponse) => {
        if (!res) {
          return;
        }
        const invoicesInfo: any[] = res.data.stripeInvoices || [];

        patchState({
          invoicesInfo,
          pendingInDraft: this.getPendingInDraft(invoicesInfo),
          loaded: true
        });
        return this.analytics.trackEvent('get_invoices', {
          orgID
        });
      })
      .catch(err => {
        patchState({
          loaded: true
        });
        this.toast.error(err.message, 'Please refresh the page!');
      });
  }

  @Action(PayInvoice)
  payInvoice({getState, patchState}: StateContext<BillingStateModel>, {payload}: PayInvoice) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.billing.payInvoice({orgID, invoice: payload.invoice})
      .then((res: ServerResponse) => {
        serverResponseToast(this.toast, res);
        const invoice = res.data.stripeInvoice;
        const stripeCustomerInfo = res.data.stripeCustomerInfo;

        const state = getState();
        let invoicesInfo = state.invoicesInfo;
        if (invoice) {
          invoicesInfo = invoicesInfo.filter(curInvoice => curInvoice.id !== invoice.id)
            .reverse().concat(invoice).reverse();
        }
        patchState({
          invoicesInfo,
          pendingInDraft: this.getPendingInDraft(invoicesInfo),
          stripeCustomerInfo
        });
        // this.store.dispatch(new GetActiveServices());
        // this.store.dispatch(new GetInactiveServices());
        // this.store.dispatch(new GetPendingServices());

        return this.analytics.trackEvent('paid_invoice', {
          orgID
        });
      })
      .catch(err => {
        patchState({
          loaded: true
        });
        this.toast.error(err.message, err.subtext);
      });
  }

  @Action(FinalizePendingSetups)
  finalizePendingServices({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: FinalizePendingSetups) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);

    return this.billing.finalizePendingSetups({
      orgID, services: payload
    }).then((res: ServerResponse) => {
      serverResponseToast(this.toast, res);
      const data = res.data;
      const invoice = data.stripeInvoice;
      const stripeCustomer = data.stripeCustomer;

      const state = getState();
      let invoicesInfo = state.invoicesInfo;

      invoicesInfo = invoicesInfo.filter(curInvoice => curInvoice.id !== invoice.id)
        .reverse().concat(invoice).reverse();

      patchState({
        invoicesInfo,
        stripeCustomerInfo: stripeCustomer,
        pendingInDraft: this.getPendingInDraft(invoicesInfo)
      });
      return this.analytics.trackEvent('finalize_pending_setups', {
        orgID
      });
    }).catch(err => {
      this.logger.error(err);
      this.toast.error(err.message);
    });
  }

  @Action(DeleteInvoice)
  deleteInvoice({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: DeleteInvoice) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);

    return this.billing.deleteInvoice({orgID, invoiceID: payload.invoiceID, action: payload.action})
      .then((res: ServerResponse) => {
        serverResponseToast(this.toast, res);
        const invoice = res.data;

        if (!invoice) {
          return;
        }

        const state = getState();
        let invoicesInfo = state.invoicesInfo;

        invoicesInfo = invoicesInfo.filter(curInvoice => curInvoice.id !== invoice.id);

        patchState({
          invoicesInfo,
          pendingInDraft: this.getPendingInDraft(invoicesInfo)
        });
        return this.analytics.trackEvent('delete_invoice', {
          orgID
        });
      }).catch(err => {
        this.logger.error(err);
        this.toast.error(err.message);
      });
  }

  @Action(DeleteInvoiceItem)
  deleteInvoiceItem({getState, setState, patchState}: StateContext<BillingStateModel>, {payload}: DeleteInvoiceItem) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);

    return this.billing.deleteInvoiceItem({
      orgID,
      invoiceID: payload.invoiceID,
      invoiceItemID: payload.invoiceItemID
    })
      .then((res: ServerResponse) => {
        serverResponseToast(this.toast, res);
        const invoice = res.data.stripeInvoice;

        if (!invoice) {
          return;
        }

        const state = getState();
        const invoicesInfo = state.invoicesInfo.filter(curInvoice => curInvoice.id !== invoice.id).concat(invoice);

        patchState({
          invoicesInfo,
          pendingInDraft: this.getPendingInDraft(invoicesInfo)
        });
      }).catch(err => {
        this.logger.error(err);
        this.toast.error(err.message);
      });
  }

  getPendingInDraft(invoices) {
    let pendingServices = {};
    const draftInvoices = invoices.filter(invoice => invoice.status === 'draft' || invoice.status === 'open');

    for (const invoice of draftInvoices) {
      const lineItems: any[] = invoice.lines.data;
      const lineItemsMeta = lineItems.reduce((result, line) => {
        // Use the unique compound key to store the invoiceID and itemID so the metric can be used elsewhere
        // with the values
        result[`${line.metadata.storeID}_${line.metadata.serviceType}`] = {
          invoiceID: invoice.id,
          invoiceItemID: line.id
        };
        return result;
      }, {});
      pendingServices = Object.assign({}, pendingServices, lineItemsMeta);
    }
    return pendingServices;
  }
}
