import {NgModule} from '@angular/core';
import {
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule, NbPopoverModule,
  NbSelectModule, NbSpinnerModule,
  NbStepperModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbWindowModule
} from '@nebular/theme';

const NB_MODULES = [
  NbLayoutModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbSelectModule,
  NbAlertModule,
  NbCheckboxModule,
  NbIconModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbStepperModule,
  NbListModule,
  NbActionsModule,
  NbSpinnerModule,
  NbPopoverModule
];

@NgModule({
  declarations: [],
  imports: [...NB_MODULES],
  exports: [...NB_MODULES]
})
export class NebularModule {
}
