import {UserProfile} from '@shared/model';

export enum ActionTypes {
  GET_PROFILES = '[Profile] GetAllProfiles',
  GET_PROFILE = '[Profile] GetAProfile',
  ADD_PROFILE = '[Profile] AddProfile',
  EDIT_PROFILE = '[Profile] EditProfile',
  DELETE_PROFILE = '[Profile] DeleteProfile'
}

export class AddProfile {
  static readonly type = ActionTypes.ADD_PROFILE;
  constructor(public payload: Partial<UserProfile>) {}
}

export class EditProfile {
  static readonly type = ActionTypes.EDIT_PROFILE;
  constructor(public payload: Partial<UserProfile>) {}
}

export class DeleteProfile {
  static readonly type = ActionTypes.DELETE_PROFILE;
  constructor(public payload: any) {}
}

export class GetProfiles {
  static readonly type = ActionTypes.GET_PROFILES;
  constructor() {}
}

export class GetAProfile {
  static readonly type = ActionTypes.GET_PROFILE;
  constructor(public payload: {feedID: string}) {}
}
