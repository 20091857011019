

export enum interceptorConstants {
  UNAUTHORIZED = 401,
  PRECONDITION_FAILED = 412,
  TOKEN_EXPIRE = 419,
  INTERNAL_ERROR = 500,
  GATEWAY_TIMEOUT = 504,
  SUCCESS = 200,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  MAXIMUM_RE_REQUEST_LIMIT = 3
}
