export interface CuratorAlert {
  id: string;
  message: string;
  level: AlertLevel | string;
  createdAt: string | number;
  updatedAt: string | number;
  feature: Feature | string;
  storeID: string;
  isResolved: boolean;
  forAdmin: boolean;
  resolvedBy: string;
  createdBy: string;
}

export enum AlertType {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
  debug = 'debug'
}

export enum AlertLevel {
  error = 'times-circle',
  success = 'check-circle',
  info = 'info-circle',
  warning = 'exclamation-triangle',
  debug = 'debug'
}

export enum Feature {
  feed = 'feed',
  campaign = 'campaign',
  template = 'template',
  billing = 'billing',
  facebook = 'facebook',
  adwords = 'adwords'
}
