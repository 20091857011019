<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="bars"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">DEALER <span class="curator"> CURATOR</span><span
      class="thin beta-text">beta</span></a>
  </div>
</div>

<div class="header-container">
  <!--  <button nbButton [status]="'primary'" type="submit" class="action-button" (click)="refreshPage()" size="small"-->
  <!--          [nbSpinner]="isRefreshing" nbSpinnerStatus="success" *ngIf="showRefresh">-->
  <!--    REFRESH-->
  <!--  </button>-->
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        nbContextMenuTag="header-menu"
        shape="semi-round"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="(providerData$ | async)[0].photoURL">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
