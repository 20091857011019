import {BillingDetails} from '@shared/model';
import Stripe = stripe.Stripe;

interface StripeFields {
  paymentMethod: string;
  customerID: string;
}

export enum ActionTypes {
  GET_PAYMENT_METHODS = '[Billing] GetPaymentMethods',
  GET_STRIPE_BILLING = '[Billing] GetStripeBilling',
  GET_INVOICES = '[Billing] GetInvoices',
  PAY_INVOICE = '[Billing] PayInvoice',
  DELETE_INVOICE = '[Billing] DeleteInvoice',
  DELETE_INVOICE_ITEM = '[Billing] DeleteInvoiceItem',
  ADD_PAYMENT_METHODS = '[Billing] AddPaymentMethods',
  FINALIZE_PENDING_SETUPS = '[Billing] FinalizePendingSetups',
  SET_PAYMENT_METHOD = '[Billing] SetPaymentMethod',
  DELETE_PAYMENT_METHOD = '[Billing] DeletePaymentMethod'
}

export class AddPaymentMethod {
  static readonly type = ActionTypes.ADD_PAYMENT_METHODS;
  constructor(public payload: StripeFields) {}
}

export class SetPaymentMethod {
  static readonly type = ActionTypes.SET_PAYMENT_METHOD;
  constructor(public payload: any) {}
}

export class FinalizePendingSetups {
  static readonly type = ActionTypes.FINALIZE_PENDING_SETUPS;
  constructor(public payload: any) {}
}

export class PayInvoice {
  static readonly type = ActionTypes.PAY_INVOICE;
  constructor(public payload: any) {}
}

export class DeleteInvoice {
  static readonly type = ActionTypes.DELETE_INVOICE;
  constructor(public payload: any) {}
}

export class DeleteInvoiceItem {
  static readonly type = ActionTypes.DELETE_INVOICE_ITEM;
  constructor(public payload: any) {}
}

export class DeletePaymentMethod {
  static readonly type = ActionTypes.DELETE_PAYMENT_METHOD;
  constructor(public payload: any) {}
}

export class GetPaymentMethods {
  static readonly type = ActionTypes.GET_PAYMENT_METHODS;
  constructor() {}
}

export class GetStripeBilling {
  static readonly type = ActionTypes.GET_STRIPE_BILLING;
  constructor() {}
}

export class GetInvoices {
  static readonly type = ActionTypes.GET_INVOICES;
  constructor() {}
}
