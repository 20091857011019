
<h1 class="main-title"><span class="thin">Dealer</span> Curator <span class="thin beta-text">beta</span></h1>
<p class="sub-title">Sign in to your account.</p>

<form (ngSubmit)="login()" [formGroup]="loginForm" aria-labelledby="sub-title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email</label>
    <input nbInput
           autocomplete="on"
           formControlName="email"
           id="input-email"
           placeholder="Email Address"
           fullWidth
           fieldSize="large"
           autofocus
           [status]="f.email.dirty ? (f.email.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.email.invalid && f.email.touched ? true : null">
    <ng-container *ngIf="f.email.invalid && f.email.touched">
      <p class="caption status-danger" *ngIf="f.email.errors?.required">
        Email is required!
      </p>
      <p class="caption status-danger" *ngIf="f.email.errors?.email">
        Email must be a valid email address!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password</label>
      <a class="forgot-password caption-2" [routerLink]="['/forgot-password']">Forgot your password?</a>
    </span>
    <input nbInput
           autocomplete="on"
           formControlName="password"
           type="password"
           id="input-password"
           placeholder="Password"
           fullWidth
           fieldSize="large"
           [status]="f.password.dirty ? (f.password.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="f.password.invalid && f.password.touched ? true : null">
    <ng-container *ngIf="f.password.invalid && f.password.touched ">
      <p class="caption status-danger" *ngIf="f.password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="f.password.errors?.minlength">
        Password must be at least 6 characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <p class="text-center pl-3 pr-3">
      By clicking "Login", you are agreeing to our
      <a href="https://www.dealercurator.com/terms.html" target="_blank"><strong>Terms & Conditions</strong></a>
      and acknowledge our
      <a href="https://www.dealercurator.com/privacy.html" target="_blank"><strong>Privacy Policy</strong></a>.
    </p>
  </div>

  <button nbButton fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || loginForm.invalid"
          [nbSpinner]="loading" nbSpinnerStatus="success"
          [class.btn-pulse]="submitted">
    Login
  </button>
</form>

<!--<section class="another-action" aria-label="Register">-->
<!--  Don't have a Curated Account? <a class="text-link" [routerLink]="['/register']">Sign Up here!</a>-->
<!--</section>-->
