import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngxs/store';
import {environment} from '@env';
import {AuthState} from '@shared/state/auth/auth.state';
import {UserProfile} from '@shared/model';
import {map, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {isAdmin} from '@shared/helpers/auth';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  headers: HttpHeaders;
  apiUrl: string;

  constructor(private databaseService: AngularFireDatabase, private firestore: AngularFirestore,
              private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  getProfilesData(): Observable<UserProfile[]> {
    const userData = this.store.selectSnapshot(AuthState.getUserData);
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const userPath = `orgs/${orgID}/users`;
    const roles = ['admin', 'user', 'readonly', 'owner'];
    if (!orgID || !isAdmin(userData.role)) {
      return of([]);
    }
    return this.firestore.collection<UserProfile>(userPath, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where(`role`, 'in', roles);
      query = query.orderBy('createdAt');
      return query;
    }).snapshotChanges().pipe(
      // take(1), // If the reads start getting expensive
      map(documents => {
        const profileObj: any[] = [];
        documents.forEach(doc => {
          const profile = doc.payload.doc.data();
          const profileID = doc.payload.doc.id;
          profileObj.push({
            uid: profileID,
            ...profile
          });
        });
        return profileObj;
      })
    );
  }

  deleteProfile(profileID) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    const userID = this.store.selectSnapshot(AuthState.getUserID);

    return this.http.post(this.getUrl('profiles/delete-profile'), {
      userID, orgID, profileID
    })
      .toPromise();
  }

  getUrl(url) {
    return `${this.apiUrl}/${url}`;
  }

  createProfile(data) {
    return this.http.post(this.getUrl('profiles/create-profile'), data)
      .toPromise();
  }

  editProfile(data) {
    return this.http.post(this.getUrl('profiles/edit-profile'), data)
      .toPromise();
  }

  sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }
}
