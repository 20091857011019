import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireDatabaseModule} from '@angular/fire/database';

import {AppComponent} from './app.component';
import {environment} from '@env';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NbDialogModule, NbGlobalPhysicalPosition, NbThemeModule, NbToastrModule} from '@nebular/theme';
import {CoreModule} from '@app/core.module';
import {AuthModule} from '@modules/auth/auth.module';
import {AppRoutingModule} from './app-routing.module';
import {LayoutModule} from './layout/layout.module';
import {ErrorInterceptor} from '@app/interceptor/error.interceptor';
import {ContentModule} from '@modules/content.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {ServiceWorkerModule} from '@angular/service-worker';
import '@stripe/stripe-js';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import {en_US} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {SharedModule} from '@shared/shared.module';
import {TokenInterceptor} from '@app/interceptor/token.interceptor';
import {FacebookModule} from "ngx-facebook";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FacebookModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    NgxsModule.forRoot(
      [],
      {developmentMode: !environment.production},
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    AppRoutingModule,

    CoreModule,
    AuthModule,
    LayoutModule,
    ContentModule,

    NbThemeModule.forRoot({name: 'dark'}),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.TOP_RIGHT
    }),
    NbDialogModule.forRoot(),
    FontAwesomeModule,
    // ServiceWorkerModule.register('/ngsw-worker.js'),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    SharedModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    ScreenTrackingService,
    UserTrackingService,
    {provide: NZ_I18N, useValue: en_US}
    // {
    //   provide: FirestoreSettingsToken,
    //   useValue: environment.local ? {
    //     host: environment.firestoreUrl,
    //     ssl: false
    //   } : undefined
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
