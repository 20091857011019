import {State, Action, Selector, StateContext, Store} from '@ngxs/store';
import {
  AddPendingService,
  AddStore, DisableActiveService,
  EditStore, EnableInactiveServices, GetActiveServices, GetInactiveServices,
  GetPendingServices,
  GetService,
  GetStore,
  GetStores, GetStoresByUrl, ReEnableDisabledService,
  RemovePendingService
} from './store.actions';
import {DealerInfo, ServerResponse, StoreData} from '@shared/model';
import {StoreService} from '@shared/service/store.service';
import {take, tap} from 'rxjs/operators';
import {ToastService} from '@app/service/util/toast.service';
import {RoutingService} from '@app/service/routing.service';
import {LoggerService} from '@app/service/logger/logger.service';
import {AnalyticsService} from '@app/service/util/analytics.service';
import {of, Subject} from 'rxjs';
import {GetInvoices} from '@shared/state/billing/billing.actions';
import {AuthState} from '@shared/state/auth/auth.state';
import {serverResponseToast} from '@shared/helpers/helpers';

export interface StoreStateModel {
  storeInfoData: Partial<StoreData>[];
  storeIDs: string[];
  storeServices: {};
  pendingServices: any[];
  activeServices: any[];
  inactiveServices: any[];
  searchedUrls: {
    [k: string]: DealerInfo[]
  };
  loaded: boolean;
}

@State<StoreStateModel>({
  name: 'store',
  defaults: {
    storeInfoData: [],
    storeIDs: [],
    storeServices: {},
    pendingServices: [],
    activeServices: [],
    inactiveServices: [],
    searchedUrls: {},
    loaded: false
  }
})
export class StoreState {
  constructor(private storeService: StoreService, private toastr: ToastService, private store: Store,
              private routingService: RoutingService, private logger: LoggerService, private analytics: AnalyticsService) {
  }

  @Selector()
  static getStoreInfoData(state: StoreStateModel) {
    return state.storeInfoData;
  }

  @Selector()
  static getLoaded(state: StoreStateModel) {
    return state.loaded;
  }

  @Selector()
  static getStoreIDs(state: StoreStateModel) {
    return state.storeIDs;
  }

  @Selector()
  static getStoreService(state: StoreStateModel) {
    return state.storeServices;
  }

  @Selector()
  static getPendingServices(state: StoreStateModel) {
    return state.pendingServices;
  }

  @Selector()
  static getActiveServices(state: StoreStateModel) {
    return state.activeServices;
  }

  @Selector()
  static getInactiveServices(state: StoreStateModel) {
    return state.inactiveServices;
  }

  @Selector()
  static getAStoreInfo(state: StoreStateModel, storeID: string) {
    return state.storeInfoData.filter(store => store.id === storeID)[0];
  }

  @Selector()
  static getSearchedUrls(state: StoreStateModel) {
    return state.searchedUrls;
  }

  @Action(AddStore)
  addStore({getState, setState, patchState}: StateContext<StoreStateModel>, {payload}: AddStore) {
    return this.storeService.createStore(payload)
      .then((response: any) => {
        const state = getState();
        const addedStore: StoreData = response.data;
        let storeInfo = state.storeInfoData;
        let storeIDs = state.storeIDs;
        if (!storeInfo) {
          storeInfo = [addedStore];
        } else {
          storeInfo = storeInfo.filter(storeData => storeData.id !== addedStore.id)
            .concat(addedStore);
        }
        if (!storeIDs) {
          storeIDs = [addedStore.id];
        } else {
          storeIDs = storeIDs.filter(storeID => addedStore.id !== storeID)
            .concat(addedStore.id);
        }
        patchState({
          storeInfoData: storeInfo,
          storeIDs,
          loaded: true
        });
        this.analytics.trackEvent('add_store', {
          storeName: payload.name,
          storeID: response.data.id
        });
        return serverResponseToast(this.toastr, response);
      })
      .catch(error => {
        this.analytics.trackEvent('add_store_error', {
          storeName: payload.name,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to create ${payload.name}`, error.message);
        this.logger.error('Store Error', error);
      });
  }

  @Action(EditStore)
  editStore({getState, setState}: StateContext<StoreStateModel>, {payload}: EditStore) {
    return this.storeService.editStore(payload)
      .then((response: any) => {
        this.analytics.trackEvent('edit_store', {
          storeName: payload.name,
          storeID: response.data.id
        });
        return serverResponseToast(this.toastr, response);
      })
      .catch(error => {
        this.analytics.trackEvent('edit_store_error', {
          storeName: payload.name,
          message: error.message
        });
        this.toastr.error(`Unable to edit ${payload.name}`, error.message);
        this.logger.error('Store Error', error);
      });
  }

  @Action(GetStores)
  getStoresData({patchState}: StateContext<StoreStateModel>) {
    return this.storeService.getStoresData().pipe(tap((result) => {
      const ids = result.map(val => val.id);
      patchState({
        storeInfoData: result,
        storeIDs: ids,
        loaded: true
      });
      this.analytics.trackEvent('get_stores', {
        count: ids.length
      });
    }));
  }

  @Action(GetStoresByUrl)
  getStoresByUrl({getState, patchState}: StateContext<StoreStateModel>, {payload}: GetStoresByUrl) {
    return this.storeService.getStoresByUrl(payload)
      .then((response: any) => {
        const state = getState();
        const foundDealers: any[] = response.data;
        const searchUrl: string = payload.searchUrl;

        serverResponseToast(this.toastr, response);
        this.analytics.trackEvent('search_dealers', {
          searchUrl: payload.searchUrl,
        });
        patchState({
          searchedUrls: Object.assign({}, state.searchedUrls, {[searchUrl]: foundDealers})
        })
      })
      .catch(error => {
        this.analytics.trackEvent('search_dealers_error', {
          storeName: payload.url,
          message: error.message,
          state: getState()
        });
        this.toastr.error(`Unable to search for ${payload.url}, if you think it's the right url, continue`, error.message);
        this.logger.error('Store Error', error);
      });
  }

  @Action(GetService)
  getStoreData({patchState, getState}: StateContext<StoreStateModel>, {payload}: GetService) {
    const storeID = payload.storeID;
    const serviceID = payload.serviceID;
    if (!storeID || !serviceID) {
      return of({});
    }
    return this.storeService.getServiceData(storeID, serviceID)
      .pipe(
        take(1)
      ).subscribe(result => {
        const service = result.data();
        if (service) {
          patchState({
            storeServices: {
              [storeID]: {
                [serviceID]: service
              }
            }
          });
        }
      });
  }

  @Action(GetPendingServices)
  getPendingServicesData({patchState, getState}: StateContext<StoreStateModel>) {
    return this.storeService.getPendingServices()
      .pipe(
        // take(1)
      ).subscribe(result => {
        patchState({
          pendingServices: result
        });
      });
  }

  @Action(GetActiveServices)
  getActiveServicesData({patchState}: StateContext<StoreStateModel>) {
    return this.storeService.getActiveServices()
      .pipe(
        // take(1)
      ).subscribe(result => {
        patchState({
          activeServices: result
        });
      });
  }

  @Action(GetInactiveServices)
  getInactiveServicesData({patchState}: StateContext<StoreStateModel>) {
    return this.storeService.getInactiveServices()
      .pipe(
        // take(1)
      ).subscribe(result => {
        patchState({
          inactiveServices: result
        });
      });
  }

  @Action(DisableActiveService)
  disableActiveServices({patchState, getState}: StateContext<StoreStateModel>, {payload}: DisableActiveService) {
    return this.storeService.disableActiveService(payload)
      .then(async (response: ServerResponse) => {
        serverResponseToast(this.toastr, response);
        const disabledService = response.data.service;
        const state = getState();
        let activeServices = state.activeServices;

        activeServices = activeServices.filter(service => service.id !== disabledService.id).concat(disabledService);
        patchState({
          activeServices
        });
        await this.analytics.trackEvent('disable_active_service', {
          serviceID: response.data.id
        });
      })
      .catch(async error => {
        this.toastr.error(`Unable to edit ${payload.name}`, error.message);
        this.logger.error('Disable Active Service Error', error);
        await this.analytics.trackEvent('disable_active_service_error', {
          id: payload.id,
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(ReEnableDisabledService)
  reEnableDisabledService({patchState, getState}: StateContext<StoreStateModel>, {payload}: ReEnableDisabledService) {
    return this.storeService.reEnableDisabledService(payload)
      .then(async (response: ServerResponse) => {
        serverResponseToast(this.toastr, response);
        const reEnabledService = response.data.service;
        const state = getState();

        let activeServices = state.activeServices;
        activeServices = activeServices.filter(service => service.id !== reEnabledService.id).concat(reEnabledService);
        patchState({
          activeServices
        });
        await this.analytics.trackEvent('reenable_disabled_service', {
          serviceID: response.data.id
        });
      })
      .catch(async error => {
        this.toastr.error(`Unable to edit ${payload.serviceName} for ${payload.storeName}`, error.message);
        this.logger.error('Service Enable Error', error);
        await this.analytics.trackEvent('reenable_disabled_service_error', {
          id: payload.id,
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(EnableInactiveServices)
  enableInactiveServices({patchState, getState}: StateContext<StoreStateModel>, {payload}: EnableInactiveServices) {
    const orgID = this.store.selectSnapshot(AuthState.getOrgID);
    return this.storeService.enableInactiveServices({orgID, services: payload})
      .then(async (response: ServerResponse) => {
        serverResponseToast(this.toastr, response);
        const enabledServices = response.data.services;
        const state = getState();
        let inactiveServices = state.inactiveServices;

        for (const key in enabledServices) {
          if (!enabledServices.hasOwnProperty(key)) {
            continue;
          }
          const enabledService = enabledServices[key];
          inactiveServices = inactiveServices.filter(service => service.id !== enabledService.id).concat(enabledService);
        }

        patchState({
          inactiveServices
        });
        this.store.dispatch(new GetInvoices());
        this.routingService.navigateToPath('billing/invoices');
        await this.analytics.trackEvent('enable_inactive_service', {
          serviceID: response.data.id
        });
      })
      .catch(async error => {
        this.toastr.error(`Unable to add the services to the invoice.`, error.message);
        this.logger.error('Inactive Service Error', error);
        await this.analytics.trackEvent('enable_inactive_service_error', {
          id: payload.id,
          message: error.message,
          state: getState()
        });
      });
  }

  @Action(RemovePendingService)
  removePendingService({patchState, getState}: StateContext<StoreStateModel>, {payload}: RemovePendingService) {
    const serviceID = payload.serviceID;
    const state = getState();
    let pendingServices = state.pendingServices;

    pendingServices = pendingServices.filter(service => service.id !== serviceID);

    patchState({
      pendingServices
    });
  }

  @Action(AddPendingService)
  addPendingService({patchState, getState}: StateContext<StoreStateModel>, {payload}: AddPendingService) {
    const addService = payload.service;
    const state = getState();
    let pendingServices = state.pendingServices;

    pendingServices = pendingServices.filter(service => service.id !== addService.id).concat(addService);

    patchState({
      pendingServices
    });
  }
}
