import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'inventory-feeds',
    loadChildren: () => import('./inventory-feeds/inventory-feeds.module').then(m => m.InventoryFeedsModule)
  },
  {
    path: 'facebook',
    loadChildren: () => import('./facebook-hub/facebook-hub.module').then(m => m.FacebookHubModule)
  },
  {
    path: 'vdp-analysis',
    loadChildren: () => import('./vdp-analysis/vdp-analysis.module').then(m => m.VdpAnalysisModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: '',
    loadChildren: () => import('./../shared/components/shared-components.module').then(m => m.SharedComponentsModule)
  }
];

const config: ExtraOptions = {
  useHash: true,
  // enableTracing: environment.local
};

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule {
}
